import DefaultFooter from 'components/Footers/DefaultFooter'
import LandingPageHeader from 'components/Headers/LandingPageHeader'
import ExamplesNavbar from 'components/Navbars/ExamplesNavbar'
import React from 'react'
import { Col, Row } from 'reactstrap'
import KTSFooter from 'components/Footers/KTSFooter'
import TNZ1 from '../assets/img/KTSImages/Images/Gallery/Gallery24.JPG'
import TNZ2 from '../assets/img/KTSImages/Images/Gallery/Gallery21.JPG'
import TNZ3 from '../assets/img/KTSImages/Images/Gallery/Gallery20.JPG'
import TNZ4 from '../assets/img/KTSImages/Images/Gallery/Gallery22.jpg'
import TNZ5 from '../assets/img/KTSImages/Images/Gallery/Gallery16.JPG'


function Tanzania() {
    return (
        <div>
            <ExamplesNavbar/>
            <LandingPageHeader/>
            <div id='content'>
                <h2 className='title text-center'>Welcome to Tanzania!</h2>
                <Row style={{marginLeft:'10%', marginRight:'10%'}} className='text-justify'>
                    <Col sm='8' md='8' lg='8'>
                        <p>
                            Tanzania is situated just south of the Equator, and is a country that possesses the,
                            epic wilderness national parks and reserves including the Serengeti National Park,
                            Ngorongoro Crater and Mount Kilimanjaro which have been designated UNESCO
                            World Heritage sites. It is the largest country in East Africa, and includes the islands
                            of Zanzibar, Pemba and Mafia. Its neighbours are Uganda, Lake Victoria, Kenya to the
                            north, Mozambique, Zambia, Rwanda and Burundi. Its capital city is Dodoma.
                            Tanzania is home to an exceptionally large number of wildlife, found in extensive
                            network of reserves, conservation areas and national parks, totalling one-fourth of
                            Tanzania’s land for wildlife viewing.
                        </p>
                        <br/>
                        
                    </Col>
                    <Col style={{alignItems:'center'}} md='4' sm='4' lg='4'>
                        <img src={TNZ1} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                </Row>
                
                <Row style={{marginLeft:'10%', marginRight:'10%', marginTop:'5%'}} className='text-justify'>
                    <Col style={{alignItems:'center', marginTop:'10%'}} md='4' sm='4' lg='4'>
                        <img src={TNZ2} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <h3 className='title'>Where to be in Tanzania</h3>
                        <h4>Lake Manyara National Park</h4>
                        <p>
                            Lake Manyara National Park is a small park at the base of the Rift Valley. Its main
                            attraction are the elephants, and is known for its groundwater forest offering a
                            change of scenery compared to the other national parks in Tanzania. Buffalo, giraffes,
                            and variety of antelope species are spotted easily in the park.
                        </p>
                        <br/>
                    </Col>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}} className='text-justify'>
                    <h4 className='text-center'>Ngorongoro Crater</h4>
                    <p>
                        The Ngorongoro Crater, located in the northern part of Tanzania, is stunningly
                        beautiful with a backdrop of the 1,968ft-high crater wall. It offers some of the best
                        wildlife viewing in Africa, such as the black rhinos which are easily seen and the
                        regular flocks of flamingo. The Ngorongoro Crater is known as one of the seven
                        wonders of Africa that captivates more guests to visit Tanzania. The area hosts more
                        than 30,000 wildlife which includes black rhinos, lions, wildebeests, zebras, Ostrich,
                        and over 500 bird species including the smallest hummingbird.
                    </p>
                    <br/>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%', marginTop:'5%'}}>
                    
                    <Col className='text-justify'>
                        <h4>Serengeti National Park</h4>
                        <p>
                            Serengeti National Park is known as a World Heritage Site, consisting of 15,000
                            kilometers in size. It is home to over 4,000 lions, 1,000 leopards, over 500 bird
                            species, and the spectacular wildebeest migration which offers first-class wildlife
                            viewing throughout the year. Serengeti’s scenery is known for its vast grassland
                            plains in the southeast and hilly and rocky regions in the northern part.
                        </p>
                        <br/>

                    </Col>
                    <Col style={{alignItems:'center'}} md='4' sm='4' lg='4'>
                        <img src={TNZ3} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}} className='text-justify'>
                    <h4 className='text-center'>Tarangire National Park</h4>
                    <p>
                    Tarangire National Park is located in northern Tanzania, occupying an area of 2,600
                    sq.km and is known for its vast variety of wildlife species. The park is named after the
                    Tarangire River that crosses through the park. The river is a primary source of fresh
                    water for thousands of elephants and other wild animals in the Tarangire Ecosystem
                    during the dry season. The dry season is good for wildlife viewing between June to
                    October as the species gather at water points around the park making it easier to
                    spot them.
                    </p>
                    <br/>
                    <p>
                    Kitibong Hill is a marvellous area in the park which is home to Cape buffaloes and
                    wild dogs. Another distinctive feature is found in the park is the Baobab Tree, also
                    known as the Tree of Life. Its massive trunk can hold upto 300 litres of water and its
                    edible seeds are a source of food for the animals.
                    </p>
                    <br/>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}} className='text-justify'>
                    <h4 className='text-center'>Ruaha National Park</h4>
                    <p>
                    The Selous Game Reserve is Africa’s largest protected game reserve, across an area
                    of 54,600 sq.km, and consists of a vast land of forests, grassy plains, mountains and
                    woodlands. The park is actually twice the size of Serengeti National Park and lies in
                    Southern Tanzania. It is rich in wildlife, consisting of major predators such as lions,
                    leopards, Nile crocodiles, buffalo, elephants and has one of the largest populations
                    of hippo.
                    </p>
                    <br/>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%', marginTop:'5%'}} className='text-justify'>
                    <Col style={{alignItems:'center'}} md='4' sm='4' lg='4'>
                        <img src={TNZ4} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <h4>Arusha National Park</h4>
                        <p>
                        Arusha National Park is the most accessible national parks, making it a convenient
                        getaway either at the beginning or end of one’s broader safari itinerary. It is a
                        wonderful wildlife paradise with an abundance of African elephants, Cape Buffalo,
                        Hippo, Zebra, Giraffe and wildebeests. It also has an all-year-round bird viewing
                        hotspot, especially between November to April when some of these birds will fly
                        from North Africa and Europe to Tanzania. Flamingos are also a major highlight of
                        Arusha National Park.
                        </p>
                        <br/>
                    </Col>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}} className='text-justify'>
                    <h4 className='text-center'>Arusha</h4>
                    <p>
                    Arusha the safari capital of Tanzania, is the gateway to Northern Safari Circuit. Its
                    bustling city is filled with a wonderful combination of markets, old colonial buildings,
                    city traffic, coffee shops, variety of restaurants and many other modern conveniences.
                    It is situated at the foot of Mount Meru.
                    </p>
                    <br/>
                    <p>
                    Things to do in Arusha:
                    <li className='ml-5'>
                        <li>
                        The Clock Tower – great meeting point for both locals and tourists
                        </li>
                        <li>
                        Central and Masai markets – offers African art and sell everything from fabrics
                        and electronics
                        </li>
                        <li>
                        Meserani Snake Park
                        </li>
                        <li>
                        Boma Museum
                        </li>
                        <li>
                        Tanzanite Experience
                        </li>
                    </li>
                    </p>
                    <br/>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%', marginTop:'5%'}}>
                    
                    <Col className='text-justify'>
                        <h4>Dar es Salaam</h4>
                        <p>
                        Dar es Salaam, which translates as ‘haven of peace’ is a fast-growing city home to
                        over 4 million residents. The city is now known for exquisite restaurants, young
                        nightlife and various beautiful beaches. It is well-connected to Zanzibar and the
                        Southern National Parks of Tanzania.
                        </p>
                        <br/>
                        <p>
                            Things to do in Dar es Salaam:
                            <li className='ml-5'>
                                <li>Discover the city's history</li>
                                <li>Beach tourism</li>
                                <li>Local cuisine</li>
                                <li>Stepping stone / starting point for weekend trip to Zanzibar</li>
                            </li>
                        </p>
                        <br/>
                    </Col>
                    <Col style={{alignItems:'center'}} md='4' sm='4' lg='4'>
                        <img src={TNZ5} style={{marginTop:'20%'}} className='img-raised'/>
                    </Col>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}} className='text-justify'>
                    <h4 className='text-center'>Mount Kilimanjaro</h4>
                    <p>
                    Mount Kilimanjaro is Africa’s tallest mountain and the world’s largest free-standing
                    mountain. This majestic mountain is a snow-capped volcano, and is the African
                    continents highest peak at 5,895 metres above sea level. It is very popular with both
                    experienced hikers and first-time hikers.
                    </p>
                    <br/>
                </Row>
            </div>
            <KTSFooter/>
            <DefaultFooter/>
        </div>
    )
}

export default Tanzania;