import DefaultFooter from 'components/Footers/DefaultFooter'
import LandingPageHeader from 'components/Headers/LandingPageHeader'
import ExamplesNavbar from 'components/Navbars/ExamplesNavbar'
import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import KTSFooter from 'components/Footers/KTSFooter'
import MNN1 from '../../../assets/img/KTSImages/Images/NrbTours/MasaiMaraAndNakuruPackage.JPG'
import MNN2 from '../../../assets/img/KTSImages/Images/NrbTours/MasaiMaraAndNakuruPackage(1).JPG'
import MNN3 from '../../../assets/img/KTSImages/Images/NrbTours/AuthenticSafariExperience.JPG'
import MNN4  from '../../../assets/img/KTSImages/Images/NrbTours/MasaiMaraAndNakuruPackage(2).JPG'
import Conditions from '../../../assets/objects/TourConditions';

function MaraNakuruNbo() {
    React.useEffect(()=>{
        window.scrollTo(0,0);
    })
    return (
        <div>
            <ExamplesNavbar/>
            <LandingPageHeader/>
            <div id='content'>
                <h2 className='title text-center'>Classic Masai Mara & Nakuru Safari</h2>
                <h3 className="text-center">5 day safari - Available All year round</h3>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <Col sm='8' md='8' lg='8'>
                        <h3>An overview</h3>
                        <p>
                        Have you imagined a not-too-short, yet not-too-long safari in Kenya?
                        <br/>
                        <br/>
                        Do you imagine viewing The Big Five in the most well-known National Parks in Kenya? Then this
                        is the perfect safari package for you!
                        <br/>
                        <br/>
                        Masai Mara is a life-long dream – book now and discover a variety of wildlife during your game
                        viewing in the Reserve.
                        <br/>
                        <br/>
                        This package includes two of the most well-known parks in Kenya, Masai Mara National Reserve
                        and Lake Nakuru National Park. Drop us an email/message to find out more!
                        <br/>
                        <br/>
                        Email: <a href="mailto:ptskenya@gmail.com">ptskenya@gmail.com</a> / <a href="mailto:info@kenyatoursafari.com">info@kenyatoursafari.com</a>
                        Social media: Follow us on Instagram as @newkenyatravel & Facebook: New Kenya Travel &
                        Tour Safaris LTD
                        </p>
                        <br/>
                    </Col>
                    <Col style={{alignItems:'center'}} md='4' sm='4' lg='4'>
                        <img src={MNN1} style={{marginBottom:'10%'}} className='img-raised'/>
                        <img src={MNN2} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                </Row>
                
                <Row style={{marginLeft:'10%', marginRight:'10%', marginTop:'5%'}}>
                    <Col style={{alignItems:'center'}} md='4' sm='4' lg='4'>
                        <img src={MNN3} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <h3 className='text-center'>A detailed look</h3>
                        <p classname='text-left'><b>Day 1: Nairobi</b></p>
                        <p className='text-justify'>
                            Upon arrival into Nairobi, clients met and transferred to the hotel.
                            <b> Overnight at Sarova Stanley or similar on bed and breakfast basis.</b>
                        </p>
                    </Col>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <p classname='text-left'><b>Day 2: Nairobi – Lake Nakuru</b></p>
                    <p className='text-justify'>
                    Following a short briefing, depart from Nairobi in the morning and drive to Lake Nakuru National Park via
                    the Great Rift Valley, arriving in time for lunch. After lunch, depart for an afternoon game drive in Lake
                    Nakuru National Park, returning to the lodge / camp for dinner and overnight.
                    <b> Overnight at Sarova Lion Hill Lodge or similar on full board basis</b>
                    </p>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%', marginTop:'5%'}}>
                    <Col style={{alignItems:'center'}} md='4' sm='4' lg='4'>
                        <img src={MNN4} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <p classname='text-left'><b>Day 3: Lake Nakuru – Masai Mara</b></p>
                        <p className='text-justify'>
                            Following an early breakfast this morning, depart for Masai Mara Game Reserve, one of East Africa’s most
                            famous reserve. The Mara consists of undulating hills and great rolling grasslands that sustain some of the
                            most varied wildlife in Africa. Arrive at the Lodge in time for lunch. After lunch, proceed for an afternoon
                            game drive, returning for dinner and overnight at the lodge / camp.
                            <b> Overnight in Masai Mara National Reserve on full board basis</b>
                        </p>
                    </Col>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <p classname='text-left'><b>Day 4: Masai Mara</b></p>
                    <p className='text-justify'>
                        Following an early breakfast, depart on a morning game drive, returning to the camp mid-morning.
                        After lunch, and perhaps a siesta, set off on another game drive and visit to the Masai Village,
                        returning to the lodge as the sunsets.   
                        <b> Overnight in Masai Mara National Reserve on full board basis</b>
                    </p>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <p classname='text-left'><b>Day 5: Masai Mara – Nairobi</b></p>
                    <p className='text-justify'>
                        Early morning game drive. After breakfast, check out from the lodge and depart for Nairobi. Arrive in
                        Nairobi in the afternoon. We will drop you off to your hotel or Jomo Kenyatta International Airport
                        depending on your request.
                    </p>
                </Row>
                <h3 className='title text-center'>End of Our Services</h3>
                <div style={{marginLeft:'10%', marginRight:'10%'}}>
                    <h4>Our Cost Includes:</h4>
                    <ul>
                        {Conditions.inclusion.map((item,i)=>(
                            <li key={i}>{item.value}</li>
                        ))}
                    </ul>
            
                    <h4>Our Cost Excludes:</h4>
                    <ul>
                    {Conditions.exclusion.map((item,i)=>(
                        <li key={i}>{item.value}</li>
                    ))}
                    </ul>
                    
                    <h4>Additional Remarks:</h4>
                    <ul>
                    {Conditions.remarks.map((item,i)=>(
                        <li key={i}>{item.value}</li>
                    ))}
                    </ul>
                </div>
                
            </div>
            <div className='send-button text-center'>
                <Button
                    color='success'
                    href="/enquiries"
                >
                    Booking Enquiry
                </Button>
                <br/>
                <br/>
            </div>
            <KTSFooter/>
            <DefaultFooter/>
        </div>
    )
}

export default MaraNakuruNbo;