import DefaultFooter from 'components/Footers/DefaultFooter'
import LandingPageHeader from 'components/Headers/LandingPageHeader'
import ExamplesNavbar from 'components/Navbars/ExamplesNavbar'
import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import KTSFooter from 'components/Footers/KTSFooter'
import MSN1 from '../../assets/img/KTSImages/Images/Gallery/Gallery24.JPG';
import MSN2 from '../../assets/img/KTSImages/Images/Gallery/Gallery21.JPG';
import MSN4 from '../../assets/img/KTSImages/Images/Gallery/Gallery16.JPG';
import Conditions from '../../assets/objects/TourConditions';

function ManyaraSerengetiNgoro() {
    React.useEffect(()=>{
        window.scrollTo(0,0);
    })
    return (
        <div>
            <ExamplesNavbar/>
            <LandingPageHeader/>
            <div id='content'>
                <h2 className='title text-center'>Tanzania Classic Package: Manyara, Serengeti & Ngorongoro</h2>
                <h3 className="title text-center">6 nights 7 days Safari - Available All Year round</h3>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <Col sm='8' md='8' lg='8'>
                        <h3>An overview</h3>
                        <p>
                        This package includes the most well-known parks in Tanzania, including Manyara National Park,
                        Serengeti National Park & Ngorongoro Crater, beginning from and ending in Arusha. Drop us an
                        email/message to find out more!
                        <br/>
                        <br/>
                        Email: <a href="mailto:ptskenya@gmail.com">ptskenya@gmail.com</a> / <a href="mailto:info@kenyatoursafari.com">info@kenyatoursafari.com</a>
                        Social media: Follow us on Instagram as @newkenyatravel & Facebook: New Kenya Travel &
                        Tour Safaris LTD
                        </p>
                        <br/>
                        <br/>
                    </Col>
                    <Col style={{alignItems:'center'}} md='4' sm='4' lg='4'>
                        <img src={MSN1} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                </Row>
                
                <Row style={{marginLeft:'10%', marginRight:'10%', marginTop:'5%'}}>
                    <Col style={{alignItems:'center'}} md='4' sm='4' lg='4'>
                        <img src={MSN2} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <h3 className='text-center'>A detailed look</h3>
                        <p classname='text-left'><b>Day 1: Arusha</b></p>
                        <p className='text-justify'>
                            On arrival into Arusha, clients met and transferred on private basis to their Arusha Hotel.
                            Remainder of the day at leisure.
                            <b>Overnight in Arusha on full board basis</b>
                            <b>Accommodation options: Arusha Coffee Lodge, Arusha Serena Hotel, Four Points by Sheraton
                            Arusha, Gran Melia, Rivertrees Country Inn, Ilboru Safari Lodge
                            Optional excursions: Arusha Half-day city tour, full-day city tour, N’giresi Village cultural visit</b>
                        </p> 
                    </Col>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <p classname='text-left'><b>Day 2: Arusha - Lake Manyara</b></p>
                    <p className='text-justify'>
                        Following breakfast and a short briefing, clients will depart for Lake Manyara National Park,
                        arriving in time for lunch. Following lunch, proceed for an afternoon game drive, returning to the
                        lodge / camp in time for dinner and overnight.
                        <b> Overnight in Lake Manyara on full board basis</b>
                        <b> Accommodation options: Lake Manyara Serena lodge, Lake Manyara Wildlife Camp, Lake
                        Manyara Kilimamoja, Maramboi Tented Lodge, Burunge Tented Camp</b>
                    </p>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%', marginTop:'5%'}}>
                    <Col style={{alignItems:'center'}} md='4' sm='4' lg='4'>
                        <img src={MSN4} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <p classname='text-left'><b>Day 3: Lake Manyara - Serengeti</b></p>
                        <p className='text-justify'>
                            Following breakfast, drive across the Ngorongoro Conservation area and into Serengeti National
                            Park. Arrive the lodge in time for lunch. After lunch, depart for an afternoon game drive, returning
                            to the lodge / camp as the sun sets.
                            <b> Overnight in Serengeti on full board basis</b>
                            <br/>
                            <br/>
                            <b>Accommodation options: Serengeti Sopa Lodge, Serengeti Serena Lodge, Kati Kati Camp, Lemala
                            Ewanjan Tented camp, Lemala Nanyukie tented lodge, Kubu Kubu Tented Lodge, Ole Serai Turner
                            Springs / Moru Kopjes / Seronera, Four Seasons Serengeti Lodge, Serengeti Simba Lodge, Mbuzi
                            Mawe tented lodge, Elewana Migration / Pioneer Camp</b>
                        </p>
                    </Col>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <p classname='text-left'><b>Day 4: Serengeti National Park</b></p>
                    <p className='text-justify'>
                        Morning and afternoon game drives in Serengeti National Park.   
                        <b> Overnight as a chosen accommodation in Serengeti</b>
                    </p>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <p classname='text-left'><b>Day 5: Serengeti - Ngorongoro</b></p>
                    <p className='text-justify'>
                        Following a morning game drive and breakfast, depart for Ngorongoro with picnic lunch boxes.
                        Arrive in the afternoon, with the remainder of the evening at leisure.
                        <b> Overnight in Ngorongoro / Karatu on full board basis.</b>
                        <b> Accommodation options: Ngorongoro Serena Lodge, Ngorongoro Sopa Lodge, Ngorongoro Farm
                        house, Ngorongoro Farmhouse Valley, Tloma Lodge, Ngorongoro Oldeani Mountain Lodge, Gibbs
                        Farm, Neptune Ngorongoro, Lemala Ngorongoro Lodge</b>
                    </p>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%', marginTop:'5%'}}>
                    <Col style={{alignItems:'center'}} md='4' sm='4' lg='4'>
                        <img src={MSN2} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <p classname='text-left'><b>Day 6: Ngorongoro Crater</b></p>
                        <p className='text-justify'>
                            Depart on a full day of game viewing in the crater with picnic lunch boxes, returning to the lodge
                            late afternoon.
                            <b> Overnight at a chosen accommodation in Ngorongoro / Karatu.</b>
                        </p>
                    </Col>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <p classname='text-left'><b>Day 7: Ngorongoro - Arusha</b></p>
                    <p className='text-justify'>
                        Following breakfast, depart for Arusha, arriving in time for lunch at Arusha Coffee Lodge or similar.
                        After lunch, clients will be dropped off to Arusha hotel or Arusha Airport / Kilimanjaro
                        International Airport for onwards arrangements.
                    </p>
                </Row>
                <h3 className='title text-center'>End of Our Services</h3>
                <div style={{marginLeft:'10%', marginRight:'10%'}}>
                    <h4>Our Cost Includes:</h4>
                    <ul>
                        {Conditions.inclusionTZ.map((item,i)=>(
                            <li key={i}>{item.value}</li>
                        ))}
                    </ul>
            
                    <h4>Our Cost Excludes:</h4>
                    <ul>
                    {Conditions.exclusion.map((item,i)=>(
                        <li key={i}>{item.value}</li>
                    ))}
                    </ul>
                    
                    <h4>Additional Remarks:</h4>
                    <ul>
                    {Conditions.remarks.map((item,i)=>(
                        <li key={i}>{item.value}</li>
                    ))}
                    </ul>
                </div>
            </div>
            <div className='send-button text-center'>
                <Button
                    color='success'
                    href="/enquiries"
                >
                    Booking Enquiry
                </Button>
            </div>
            <KTSFooter/>
            <DefaultFooter/>
        </div>
    )
}

export default ManyaraSerengetiNgoro;