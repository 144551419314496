import DefaultFooter from 'components/Footers/DefaultFooter'
import LandingPageHeader from 'components/Headers/LandingPageHeader'
import ExamplesNavbar from 'components/Navbars/ExamplesNavbar'
import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import KTSFooter from 'components/Footers/KTSFooter'
import MSG1 from '../../../assets/img/KTSImages/Images/MsaTours/MombasaGetaway.jpg'
import MSG2 from '../../../assets/img/KTSImages/Images/MsaTours/MombasaGetaway(1).JPG'
import MSG3 from '../../../assets/img/KTSImages/Images/MsaTours/MombasaGetaway(2).JPG'
import C2C2 from '../../../assets/img/KTSImages/Images/MsaTours/CoasttoCitySafariPackage(1).JPG'
import C2C3 from '../../../assets/img/KTSImages/Images/MsaTours/CoasttoCitySafariPackage(2).JPG'

function MsaGetaway() {
    React.useEffect(()=>{
        window.scrollTo(0,0);
    })
    return (
        <div>
            <ExamplesNavbar/>
            <LandingPageHeader/>
            <div id='content' style={{color:'black'}}>
                <h2 className='title text-center'>Mombasa Excusrisons</h2>
                <h3 className="text-center">Available All year round</h3>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <Col sm='8' md='8' lg='8'>
                        <h3>An overview</h3>
                        <p>
                        Have you imagined a not-too-short, yet not-too-long safari in Kenya?
                        Would you like to visit a range of safari parks and experience a range of leisure accommodation
                        and hospitality?
                        <br/>
                        <br/>
                        Do you imagine viewing The Big Five in the most well-known National Parks in Kenya? Then this
                        is the perfect safari package for you!
                        <br/>
                        <br/>
                        This package includes some of the most well-known attractions in Mombasa (North and South
                        Coast). Drop us an email/message to find out more!
                        <br/>
                        <br/>
                        Email: <a href="mailto:ptskenya@gmail.com">ptskenya@gmail.com</a> / <a href="mailto:info@kenyatoursafari.com">info@kenyatoursafari.com</a>
                        Social media: Follow us on Instagram as @newkenyatravel & Facebook: New Kenya Travel &
                        Tour Safaris LTD
                        </p>
                        <br/>
                    </Col>
                    <Col style={{alignItems:'center'}} md='4' sm='4' lg='4'>
                        <img src={MSG1} style={{marginBottom:'10%'}} className='img-raised'/>
                        <img src={MSG2} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                </Row>
                <h3 className='title text-center'>A detailed look</h3>
                <Row style={{marginLeft:'10%', marginRight:'10%', marginTop:'5%'}}>
                    <p classname='text-left'><b>1: Mombasa City Tour / AKA Mombasa Highlights</b></p>
                    <p className='text-justify'>
                    Half day – this half day tour shows the sites of Kenya’s oldest and second largest
                    Town. Stops include Fort Jesus (designed by the Italian architect Cairati in the 16th
                    century) and the Akamba Wood Carvers
                    </p>
                    <br/>
                    <p>
                    Full Day – This includes Fort Jesus, Akamba Wood Carvers, Vegetable Market, Haller
                    Park and Dhow Harbour. Includes Lunch.
                    </p>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <p classname='text-left'><b>2: Tsavo East National Park</b></p>
                    <p className='text-justify'>
                        A.K.A The Lions Lair. A full day game drive in the largest park
                        in Kenya. Morning departure, includes lunch, returning to Mombasa by evening.
                    </p>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%', marginTop:'5%'}}>
                    <p classname='text-left'><b>3: Shimba Hills (Full Day)</b></p>
                    <p className='text-justify'>
                    Depart for full day at Shimba Hills. Take a hike to the waterfalls in
                    the morning. Drive back to Shimba Lodge for lunch, one of the tree top hotels, where you
                    have a chance to see animals at the water hole. After lunch, head on for game drive, and
                    then return back to hotel around 1700hrs. Late afternoon, drop off to your South Coast
                    hotel in time for dinner and overnight.
                    <br/>
                    <b>Tour Highlights: </b>Sheldricks Falls, Coastal rainforest, bird viewing, Various Viewpoints, lunch
                    included. Entrance fees and return transport included.
                    </p>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <p classname='text-left'><b>4: Full Day at Ngutuni{" "}</b></p>
                    <p className='text-justify'>
                    A full day game drive in Ngutuni Wildlife Conservancy. Includes
                    lunch at a safari lodge.
                    </p>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <p classname='text-left'><b>5: Full Day at Wasini Islands</b></p>
                    <p className='text-justify'>
                    Pick up early morning and proceed on this amazing sea
                    adventure to Wasini Island. Enjoy your relaxed scenic drive to Shimoni jet – the
                    location you’ll meet your Dhow Operators. Start from Diani Beach, enjoy the
                    spectacular dolphins sightseeing, lunch at Wasini island, and tour around the island.
                    and return in the evening. It’s a full day Excursion safari.
                    </p>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <p classname='text-left'><b>6: Full Day Malindi Tour</b></p>
                    <p className='text-justify'>
                    This excursion begins from Mombasa in the morning
                    and return to Mombasa in the evening. The town is located approximately 120kms
                    from Mombasa town, and tourism is a major industry in Malindi. You will spend the
                    whole day exploring and enjoying the northern part of the coastal line and learn all
                    its historical and cultural highlights, including the Vasco Da Gama Pillar, Gede Ruins,
                    glass bottomed boat in Malindi Marine Park and view the reptiles at the Snake Farm.
                    </p>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%', marginTop:'5%'}}>
                    <p classname='text-left'><b>7: Watamu Tour (Full Day)</b></p>
                    <p className='text-justify'>
                    Watamu beach, Watamu National Marine Park. Lunch at a
                    restaurant in Watamu, Dhow cruise in mangroves of Mida Creek.
                    </p>  
                </Row>
                <h3 className='title text-center'>End of Our Services</h3>
            </div>
            <div className='send-button text-center'>
                <Button
                    color='success'
                    href="/enquiries"
                >
                    Booking Enquiry
                </Button>
                <br/>
                <br/>
            </div>
            <KTSFooter/>
            <DefaultFooter/>
        </div>
    )
}

export default MsaGetaway;