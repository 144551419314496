import DefaultFooter from 'components/Footers/DefaultFooter'
import LandingPageHeader from 'components/Headers/LandingPageHeader'
import ExamplesNavbar from 'components/Navbars/ExamplesNavbar'
import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import KTSFooter from 'components/Footers/KTSFooter'
import SFN1 from '../../assets/img/KTSImages/Images/Gallery/Gallery1.JPG';
import SFN2 from '../../assets/img/KTSImages/Images/Gallery/Gallery9.JPG';
import SFN3 from '../../assets/img/KTSImages/Images/Gallery/Gallery6.JPG';
import SFN4 from '../../assets/img/KTSImages/Images/Gallery/Gallery13.JPG';
import Conditions from '../../assets/objects/TourConditions';


function SerengetiFlyIn() {
    React.useEffect(()=>{
        window.scrollTo(0,0);
    })
    return (
        <div>
            <ExamplesNavbar/>
            <LandingPageHeader/>
            <div style={{marginLeft:'10%', marginRight:'10%'}}>
                <h2 className='title text-center mb-0' id='content'>Serengeti Fly-in</h2>
                <h3 className='title text-center'>2 nights, 3 days</h3>
                <h4 className='text-center'>Available All year Round</h4>
                <Row>
                    <h3 className='text-center'>An overview</h3>
                    <p className='text-justify'>
                        This package includes a visit to the famous Serengeti National Park, beginning from and ending in
                        Arusha. Drop us an email/message to find out more!
                        <br/>
                        <br/>
                        Email: <a href="mailto:ptskenya@gmail.com">ptskenya@gmail.com</a> / <a href="mailto:info@kenyatoursafari.com">info@kenyatoursafari.com</a>
                        Social media: Follow us on Instagram as @newkenyatravel & Facebook: New Kenya Travel &
                        Tour Safaris LTD
                        <br/>
                        <br/>
                    </p>
                </Row>
                <Row>
                    <Col>
                        <img src={SFN1} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <img src={SFN2} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <img src={SFN3} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                </Row>
                <Row>
                    <h3 className='title text-center'>Detailed View</h3>
                </Row>
                <Row>
                    <p classname=''><b>Day 1: Arusha - Serengeti</b></p>
                    <p className='text-justify'>
                        Clients met early morning at a hotel in Arusha and transferred to Arusha Airport for Auric Air Flight
                        to Serengeti. On arrival into Serengeti, clients met by lodge / camp 4x4 vehicle and transferred to
                        the camp in time for lunch. Following lunch, depart for an afternoon shared game drive in
                        Serengeti National Park, returning to the camp as the sun sets.
                        <b> Overnight in Serengeti on full board basis.</b>
                        <br/>
                        <b>Accommodation options: Serengeti Serena Lodge, Kati Kati Camp, Lemala Ewanjan Tented camp,
                        Lemala Nanyukie tented lodge, Kubu Kubu Tented Lodge, Ole Serai Turner Springs / Moru Kopjes
                        / Seronera, Four Seasons Serengeti Lodge, Serengeti Simba Lodge, Mbuzi Mawe tented lodge,
                        Elewana Migration / Pioneer Camp, or similar (to be mentioned at the time of booking request).</b>
                    </p>
                    <p classname='text-left'><b>Day 2: Serengeti National Park</b></p>
                    <p className='text-justify'>
                        Morning and afternoon scheduled shared game drives in the park.
                        <br/>
                        <b> Overnight at a chosen accommodation in Serengeti</b>
                    </p>
                    <p classname='text-left'><b>Day 3: Serengeti - Arusha</b></p>
                    <p className='text-justify'>
                        Following breakfast, clients will be dropped off at the nearest airstrip for their Auric Air flight to
                        Arusha, departing at 1100hrs, arriving Arusha at 1215hrs. On arrival into Arusha, clients transferred
                        for lunch at Arusha Coffee Lodge or similar, and then transferred to Arusha Airport / Kilimanjaro
                        International Airport / Arusha hotel for their onward arrangements.
                    </p>
                </Row>
                <Row>
                    <h3 className='title text-center'>End of Our Services</h3>
                </Row>
                <h4>Our Cost Includes:</h4>
                <ul>
                    {Conditions.inclusionTZ.map((item,i)=>(
                        <li key={i}>{item.value}</li>
                    ))}
                </ul>
        
                <h4>Our Cost Excludes:</h4>
                <ul>
                {Conditions.exclusion.map((item,i)=>(
                    <li key={i}>{item.value}</li>
                ))}
                </ul>
                
                <h4>Additional Remarks:</h4>
                <ul>
                {Conditions.remarks.map((item,i)=>(
                    <li key={i}>{item.value}</li>
                ))}
                </ul>
            </div>            
            <div className='send-button text-center'>
                <Button
                    color='success'
                    href="/enquiries"
                >
                    Booking Enquiry
                </Button>
            </div>
            <KTSFooter/>
            <DefaultFooter/>
        </div>
    )
}

export default SerengetiFlyIn;