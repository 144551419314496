import DefaultFooter from 'components/Footers/DefaultFooter'
import LandingPageHeader from 'components/Headers/LandingPageHeader'
import ExamplesNavbar from 'components/Navbars/ExamplesNavbar'
import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import KTSFooter from 'components/Footers/KTSFooter'
import CLS1 from '../../../assets/img/KTSImages/Images/NrbTours/ClassicPackage.JPG'
import CLS3 from '../../../assets/img/KTSImages/Images/NrbTours/ClassicPackage(2).JPG'
import CLS4  from '../../../assets/img/KTSImages/Images/NrbTours/ClassicPackage(3).JPG'
import Conditions from '../../../assets/objects/TourConditions';

function ClassicKenyaNbo() {
    React.useEffect(()=>{
        window.scrollTo(0,0);
    })
    return (
        <div>
            <ExamplesNavbar/>
            <LandingPageHeader/>
            <div id='content' style={{color:'black'}}>
                <h2 className='title text-center'>Classic Kenyan Safari</h2>
                <h3 className="text-center">6 Nights 7 Days Safari - All year round</h3>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <Col sm='8' md='8' lg='8'>
                        <h3>An overview</h3>
                        <p>
                        This package includes some of the most well-known parks in Kenya, from Masai Mara National
                        Reserve, Lake Nakuru National Park and Amboseli National Park, beginning from and ending in
                        Nairobi Airport. Drop us an email/message to find out more!
                        <br/>
                        <br/>
                        Email: <a href="mailto:ptskenya@gmail.com">ptskenya@gmail.com</a> / <a href="mailto:info@kenyatoursafari.com">info@kenyatoursafari.com</a>
                        Social media: Follow us on Instagram as @newkenyatravel & Facebook: New Kenya Travel &
                        Tour Safaris LTD
                        </p>
                        <br/>
                    </Col>
                    <Col style={{alignItems:'center'}} md='4' sm='4' lg='4'>
                        <img src={CLS1} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                </Row>
                <h3 className='text-center'>A detailed look</h3>
                <Row style={{marginLeft:'10%', marginRight:'10%', marginTop:'5%'}}>
                    <Col style={{alignItems:'center'}} md='4' sm='4' lg='4'>
                        <img src={CLS3} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <p classname='text-left'><b>Day 1: Nairobi</b></p>
                        <p className='text-justify'>
                            Upon arrival into Nairobi, clients met and transferred on private basis to the hotel.
                            <br/>
                            <b>Overnight in a Cozy room on bed and breakfast basis.</b>
                        </p>
                        
                    </Col>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <p classname='text-left'><b>Day 2: Nairobi - Masai Mara</b></p>
                    <p className='text-justify'>
                        Following an early morning breakfast, depart for Masai Mara National Reserve, one of East Africa’s
                        most famous reserve. The Mara consists of undulating hills and great rolling grasslands that sustain
                        some of the most varied wildlife in Africa. Have lunch at the lodge and some leisure time. Shortly
                        after, depart for an afternoon game drive, returning to the lodge late afternoon. Enjoy some
                        leisure facilities at the lodge/camp, followed by dinner and overnight.
                    <br/>
                    <b>Overnight in Masai Mara on full board basis.</b>
                    </p>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%', marginTop:'5%'}}>
                    <Col style={{alignItems:'center'}} md='4' sm='4' lg='4'>
                        <img src={CLS4} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <p classname='text-left'><b>Day 3: Full-day game drive in Masai Mara</b></p>
                        <p className='text-justify'>
                            Following an early breakfast, depart on a morning game drive, returning to the camp mid-morning.
                            After lunch, and perhaps a siesta, set off on another game drive and visit to the Masai Village,
                            returning to the lodge as the sunsets.
                            <br/>
                            <b>Overnight in Masai Mara on full board basis.</b>
                        </p>
                    </Col>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <p classname='text-left'><b>Day 4: Masai Mara – Lake Nakuru</b></p>
                    <p className='text-justify'>
                        After breakfast, check out and depart for Nakuru, arriving in time for lunch. Following lunch,
                        depart for an afternoon game drive in Lake Nakuru National Park where you will have opportunity
                        to spot the endangered White Rhino and Black Rhino, Leopards, Giraffes, plenty of antelopes,
                        Flamingos and over 450 bird species!    
                        <br/>
                        <b>Overnight in Lake Nakuru full board basis.</b>
                    </p>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <p classname='text-left'><b>Day 5: Lake Nakuru – Amboseli National Park</b></p>
                    <p className='text-justify'>
                        Following an early breakfast, continue to Lake Naivasha for a boat ride. Leaving the Rift Valley
                        behind, proceed to Nairobi for an early lunch (or picnic lunch dependent on road conditions) and
                        continue south towards Amboseli, arriving at your lodge by late afternoon.
                        <br/>
                        <b>Journey Time: Approximately 8 hours.</b>
                        <br/>
                        <b>Overnight in Amboseli on a full board basis.</b>
                    </p>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <div>
                    <p classname='text-left'><b>Day 6: Amboseli National Park</b></p>
                    <p className='text-justify'>
                        Morning and afternoon game drives in Amboseli, returning to the lodge as the sun sets.
                        <br/>
                        <b>Overnight in Amboseli on a full board basis.</b>
                    </p>
                    </div>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <p classname='text-left'><b>Day 7: Amboseli – Nairobi</b></p>
                    <p className='text-justify'>
                        Following an early breakfast, catch your last views of the majestic Mount Kilimanjaro as you
                        depart Amboseli. From here turn north across the beautiful and seemingly never-ending Athi
                        Plains, arriving in Kenya's bustling capital city of Nairobi by mid-day. Once in the city, clients will be
                        dropped off at the drop off point in the city centre or at the airport for ongoing travel
                        <br/>
                        <b>Journey Time: Approximately 4.5 hours</b>
                    </p>
                </Row>
                <div style={{marginLeft:'10%', marginRight:'10%'}}>
                <h3 className='title text-center'>End of Our Services</h3>
                <h4>Our Cost Includes:</h4>
                <ul>
                    {Conditions.inclusion.map((item,i)=>(
                        <li key={i}>{item.value}</li>
                    ))}
                </ul>
        
                <h4>Our Cost Excludes:</h4>
                <ul>
                {Conditions.exclusion.map((item,i)=>(
                    <li key={i}>{item.value}</li>
                ))}
                </ul>
                
                <h4>Additional Remarks:</h4>
                <ul>
                {Conditions.remarks.map((item,i)=>(
                    <li key={i}>{item.value}</li>
                ))}
                </ul>
                </div>
            </div>
            <div className='send-button text-center'>
                <Button
                    color='success'
                    href="/enquiries"
                >
                    Booking Enquiry
                </Button>
                <br/>
                <br/>
            </div>
            <KTSFooter/>
            <DefaultFooter/>
        </div>
    )
}

export default ClassicKenyaNbo;