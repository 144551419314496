import DefaultFooter from 'components/Footers/DefaultFooter'
import LandingPageHeader from 'components/Headers/LandingPageHeader'
import ExamplesNavbar from 'components/Navbars/ExamplesNavbar'
import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import KTSFooter from 'components/Footers/KTSFooter'
import SAL1 from '../../assets/img/KTSImages/Images/Gallery/Gallery2.JPG';
import SAL2 from '../../assets/img/KTSImages/Images/Gallery/Gallery10.JPG';
import SAL3 from '../../assets/img/KTSImages/Images/Gallery/Gallery13.JPG';
import Conditions from '../../assets/objects/TourConditions';


function SecretsofEALong() {
    React.useEffect(()=>{
        window.scrollTo(0,0);
    })
    return (
        <div>
            <ExamplesNavbar/>
            <LandingPageHeader/>
            <div style={{marginLeft:'10%', marginRight:'10%'}}>
                <h2 className='title text-center mb-0' id='content'>The Secrets of East Africa</h2>
                <h3 className='title text-center'>15 nights, 16 days</h3>
                <h4 className='text-center'>Available All year Round</h4>
                <Row>
                    <h3 className='text-center'>An overview</h3>
                    <p className='text-justify'>
                        Do you dream of experiencing a fresh and unique twist to an African safari with its culture, history
                        and wildlife? Then this is the perfect safari package for you!
                        <br/>
                        <br/>
                        This package includes some of the most well-known parks in Kenya and Tanzania, from Samburu
                        National Reserve, Masai Mara, Lake Nakuru National Park to Amboseli National Park, Lake
                        Manyara National Park, and Serengeti National Park, beginning from Nairobi and ending in Arusha.
                        Drop us an email/message to find out more!
                        <br/>
                        <br/>
                        Email: <a href="mailto:ptskenya@gmail.com">ptskenya@gmail.com</a> / <a href="mailto:info@kenyatoursafari.com">info@kenyatoursafari.com</a>
                        Social media: Follow us on Instagram as @newkenyatravel & Facebook: New Kenya Travel &
                        Tour Safaris LTD
                        <br/>
                        <br/>
                    </p>
                </Row>
                <Row>
                    <Col>
                        <img src={SAL1} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <img src={SAL2} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <img src={SAL3} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                </Row>
                <div style={{marginLeft:'10%', marginRight:'10%', marginTop:'5%'}}>
                    <h3 className='text-center'>A detailed look</h3>
                </div>
                <Row>
                    <p classname=''><b>Day 1: Nairobi</b></p>
                    <p className='text-justify'>
                        On arrival into Nairobi, clients transferred on private basis to the hotel.
                        <b> Overnight in Nairobi on full board basis.</b>
                        <b> Accommodation options: Eka Hotel, 2.5.4 Ole Sereni Hotel, Four Points Sheraton Airport hotel,
                        Sarova Stanley.</b>
                    </p>
                    <p classname='text-left'><b>Day 2: Nairobi – Samburu National Reserve</b></p>
                    <p className='text-justify'>
                        0700HRS after breakfast in Nairobi and a short briefing, depart for Samburu National Reserve,
                        arriving in time for lunch. Following lunch, depart for your first afternoon game drive. Some of
                        Kenya’s rarest species like the long-necked gerenuk, Grevy’s Zebras, reticulated giraffe, beisa oryx
                        and blue shanked Somali Ostrich can be seen here. Because of the sheer rarity and beauty of the
                        rugged terrain, you spend two nights here.
                        <b> Overnight in Samburu National Reserve on full board basis.</b>
                        <b> Accommodation options: Samburu Intrepids, Samburu Elephant Camp, Samburu Simba, Larsens
                        Tented Camp (luxury camo along the Ewaso Nyiro River), or similar.</b>
                    </p>
                    <p classname='text-left'><b>Day 3: Samburu National Reserve</b></p>
                    <p className='text-justify'>
                        Morning and afternoon game drives in the reserve. <b>Overnight in Samburu National Reserve on full board basis</b>
                    </p>
                    <p classname='text-left'><b>Day 4: Samburu –Lake Nakuru</b></p>
                    <p className='text-justify'>
                        Leaving Samburu for lake Nakuru after breakfast, proceed to Lake Nakuru National Park, home of
                        the famous, millions of seasonal pink Flamingoes and other aquatic birds. Other residents include
                        buffalo, Rothschild giraffe, eland, lions, leopards, and black rhino. It goes without saying that this
                        lush environment is most definitely a bird watches paradise. Arrive in time for lunch. After lunch,
                        depart for an afternoon game drive, returning to the lodge for dinner and overnight.
                        <b> Overnight in Lake Nakuru on full board basis.</b>
                        <b> Accommodation options: Lake Nakuru Lodge, Sarova Lionhill Camp, Lake Nakuru Sopa Lodge</b>
                    </p>
                    <p classname='text-left'><b>Day 5: Lake Nakuru – Lake Naivasha</b></p>
                    <p className='text-justify'>
                        After breakfast, depart for Lake Naivasha, arriving in time for lunch. After lunch, enjoy an
                        afternoon boat riding experience on Lake Naivasha, with the remainder of the evening at leisure.
                        <b> Overnight in Lake Naivasha on full board basis.</b>
                        <b> Accommodation options: Lake Naivasha lodge, Naivasha Sopa Lodge, Lake Naivasha Country
                        Club</b>
                    </p>
                    <p classname='text-left'><b>Day 6: Lake Naivasha – Masai Mara</b></p>
                    <p className='text-justify'>
                        Following an early morning breakfast, depart for Masai Mara National Reserve, one of East Africa’s
                        most famous reserve. The Mara consists of undulating hills and great rolling grasslands that sustain
                        some of the most varied wildlife in Africa. Have lunch at the lodge and some leisure time. Shortly
                        after, depart for an afternoon game drive, returning to the lodge late afternoon. Enjoy some
                        leisure facilities at the lodge/camp, followed by dinner and overnight.
                        <b> Overnight in Masai Mara on full board basis.</b>
                        <b> Accommodation options: Mara Serena Lodge, Sarova Mara Game Camp, Mara Sopa lodge,
                        Keekorok Lodge, Ashnil Mara Camp, Mara Treetops, Mara Fig Tree, Mara Sentrim Camp, Mara
                        Simba Lodge, Zebra Plains Mara Camp, Elewana Sand Rivers Camp or similar</b>
                    </p>
                    <p classname='text-left'><b>Day 7: Full-day game drive in Masai Mara</b></p>
                    <p className='text-justify'>
                        After breakfast, depart for full day game drive in Masai Mara game reserve with packed lunch
                        boxes. You will have the opportunity to see Mara river, a river in Mara Region in Tanzania and
                        Narok County in Kenya, and lies across the migration path of ungulates in the Serengeti/Masai
                        Mara game reserves. Hippopotami and Nile crocodiles are found in large groups in the Mara and
                        Talek rivers. Leopards, hyenas, cheetahs, jackals, and bat-eared foxes can also be found in the
                        reserve. The plains between the Mara River and the Esoit Siria Escarpment are probably the best
                        area for game viewing, in particular regarding lion and cheetah.
                        <br/>
                        Return to the lodge/camp late afternoon for dinner.
                        <b> Overnight in Masai Mara on full board basis</b>
                    </p>
                    <p classname='text-left'><b>Day 8: Masai Mara – Nairobi</b></p>
                    <p className='text-justify'>
                        Following breakfast this morning, drive towards Nairobi, arriving late afternoon. Check in at a
                        Nairobi hotel, with the remainder of the afternoon at leisure. Dinner at Carnivore Restaurant /
                        Haandi Restaurant.
                        <b> Overnight in Nairobi on full board basis</b>
                        <b> Accommodation options: Eka Hotel, 2.5.4 Ole Sereni Hotel, Four Points Sheraton Airport hotel,
                        Sarova Stanley.</b>
                    </p>
                    <p classname='text-left'><b>Day 9: Nairobi – Amboseli</b></p>
                    <p className='text-justify'>
                        Following breakfast this morning, depart for Amboseli National park, arriving in time for lunch.
                        Amboseli National Park is famous known for the spectacular views of Mount Kilimanjaro, the highest
                        free-standing mountain in Africa. Following lunch at the lodge and a siesta, depart for an
                        afternoon game drive, returning to the lodge as the sun sets for dinner and overnight.
                        <b> Overnight at a chosen accommodation in Amboseli on full board basis.</b>
                        <b> Accommodation options: Amboseli Serena Lodge, Ol Tukai Lodge, Amboseli Sentrim, Amboseli
                        Kibo Camp, Elewana Tortillis Camp or similar</b>
                    </p>
                    <p classname='text-left'><b>Day 10: Amboseli National Park</b></p>
                    <p className='text-justify'>
                        Following breakfast, depart for a full day game drive in Amboseli National Park with picnic lunch
                        boxes. Return to the lodge late afternoon and enjoy the leisure facilities until dinner.
                        <b> Overnight at a chosen accommodation in Amboseli on full board basis.</b>
                    </p>
                    <p classname='text-left'><b>Day 11: Amboseli – Arusha – Lake Manyara</b></p>
                    <p className='text-justify'>
                        After breakfast, depart for Arusha, arriving in time for lunch. After lunch, continue to Lake Manyara
                        arriving late afternoon. Enjoy a game drive as you enter the park en route to the lodge. Dinner and
                        overnight in Lake Manyara.
                        <b> Overnight in Lake Manyara on full board basis.</b>
                        <b> Accommodation options: Lake Manyara Serena lodge, Lake Manyara Wildlife Camp, Lake
                        Manyara Kilimamoja, Maramboi Tented Lodge, Burunge Tented Camp</b>
                    </p>
                    <p classname='text-left'><b>Day 12: Lake Manyara – Serengeti</b></p>
                    <p className='text-justify'>
                        Following breakfast, drive across the Ngorongoro Conservation area and into Serengeti National
                        Park. Arrive the lodge in time for lunch. After lunch, depart for an afternoon game drive, returning
                        to the lodge / camp as the sun sets.
                        <b> Overnight in Serengeti on full board basis.</b>
                        <b> Accommodation options: Serengeti Sopa Lodge, Serengeti Serena Lodge, Kati Kati Camp, Lemala
                        Ewanjan Tented camp, Lemala Nanyukie tented lodge, Kubu Kubu Tented Lodge, Ole Serai Turner
                        Springs / Moru Kopjes / Seronera, Four Seasons Serengeti Lodge, Serengeti Simba Lodge, Mbuzi
                        Mawe tented lodge, Elewana Migration / Pioneer Camp</b>
                    </p>
                    <p classname='text-left'><b>Day 13: Serengeti National Park</b></p>
                    <p className='text-justify'>
                        Morning and afternoon game drives in Serengeti National Park.
                        <b> Overnight as a chosen accommodation in Serengeti</b>
                    </p>
                    <p classname='text-left'><b>Day 14: Serengeti – Ngorongoro</b></p>
                    <p className='text-justify'>
                        Following a morning game drive and breakfast, depart for Ngorongoro with picnic lunch boxes.
                        Arrive in the afternoon, with the remainder of the evening at leisure.
                        <b> Overnight in Ngorongoro / Karatu on full board basis.</b>
                        <b> Accommodation options: Ngorongoro Serena Lodge, Ngorongoro Sopa Lodge, Ngorongoro Farm
                        house, Ngorongoro Farmhouse Valley, Tloma Lodge, Ngorongoro Oldeani Mountain Lodge, Gibbs
                        Farm, Neptune Ngorongoro, Lemala Ngorongoro Lodge</b>
                    </p>
                    <p classname='text-left'><b>Day 15: Ngorongoro Crater</b></p>
                    <p className='text-justify'>
                        Depart on a full day of game viewing in the crater with picnic lunch boxes, returning to the lodge
                        late afternoon.
                        <b> Overnight at a chosen accommodation in Ngorongoro / Karatu.</b>
                    </p>
                    <p classname='text-left'><b>Day 16: Ngorongoro – Arusha</b></p>
                    <p className='text-justify'>
                        Following breakfast, depart for Arusha, arriving in time for lunch at Arusha Coffee Lodge or similar.
                        After lunch, clients will be dropped off to Arusha hotel or Arusha Airport / Kilimanjaro
                        International Airport for onwards arrangements.
                    </p>
                </Row>
                <Row>
                    <h3 className='title text-center'>End of Our Service</h3>
                </Row>
                <div>
                <h4>Our Cost Includes:</h4>
                <ul>
                    {Conditions.inclusion.map((item,i)=>(
                        <li key={i}>{item.value}</li>
                    ))}
                </ul>
        
                <h4>Our Cost Excludes:</h4>
                <ul>
                {Conditions.exclusion.map((item,i)=>(
                    <li key={i}>{item.value}</li>
                ))}
                </ul>
                
                <h4>Additional Remarks:</h4>
                <ul>
                {Conditions.remarks.map((item,i)=>(
                    <li key={i}>{item.value}</li>
                ))}
                </ul>

                </div>
                
            </div>            
            <div className='send-button text-center'>
                <Button
                    color='success'
                    href="/enquiries"
                >
                    Booking Enquiry
                </Button>
            </div>
            <KTSFooter/>
            <DefaultFooter/>
        </div>
    )
}

export default SecretsofEALong;