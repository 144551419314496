import React, { useEffect, useState } from "react";

// reactstrap components
import { Button, Carousel, CarouselIndicators, CarouselItem, Container } from "reactstrap";


const items = [
  {
    src: require("assets/img/KTSImages/Images/OldImages/IMG_0127.JPG"),
    altText: "The Kenyan Safari, Kenya",
    caption: "The Kenyan Safari",
    description: "Views at Tsavo on the tour",
    page: "/kenya/nairobi/kenyan-safari"
  },
  {
    src: require("assets/img/KTSImages/Images/OldImages/497e16ce-5b6b-45f3-8f9d-8954432794d8.JPG"),
    altText: "Tsavo National Park, Kenya",
    caption: "Tsavo National Park, Kenya",
    description: "Serenity defined at Tsavo National Park, Kenya",
    page: "/kenya/mombasa/tsavo-to-amboseli"
  },
  {
    src: require("assets/img/KTSImages/Images/OldImages/e2317af3-6ad8-4fde-9884-89a5aecc7415.JPG"),
    altText: "The Grand Safari, Kenya",
    caption: "The Grand Safari, Kenya",
    description: "Join us in an exploration escapade",
    page: "/kenya/nairobi/the-grand-safari"
  }
];

// core components

function LandingPageHeader() {

  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);

  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const [anchorTarget, setAnchorTarget] = useState();

  useEffect(()=>{
    setAnchorTarget(document.getElementById("content"));
  })

  const contentScroll = (e) => {
    e.preventDefault()
    anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    <>
      <div className="page-header page-header-small align" style={{height:'auto', width:'100%', maxHeight:'600px', minHeight:'200px'}}>
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        <Carousel
          activeIndex={activeIndex}
          next={next}
          previous={previous}
        >
          
          {items.map((item)=>{
            return(
              <CarouselItem
                onExiting={onExiting}
                onExited={onExited}
                key={item.src}
              >
                <div className="" ref={pageHeader}>
                  <img src={item.src} alt={item.altText} style={{maxWidth:'100%',maxHeight:'600px', height:'inherit', width:'100%', overflow:'hidden'}}/>
                </div>
                {window.innerWidth > 1000 && (
                    <div className="content-center">
                    <Container className="content-center" style={{marginTop:'40%'}}>
                    <h2 className="title">{item.caption}</h2>
                    <h5>{item.description}<a href={item.page} style={{color:'#ffffff80'}}> Learn More...</a> </h5>
                    <div className="text-center">
                      <Button
                        className="btn-icon btn-round"
                        color="info"
                        href="https://www.facebook.com/newkenyatour18/"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fab fa-facebook-square"></i>
                      </Button>
                      <Button
                        className="btn-icon btn-round"
                        color="info"
                        href="https://www.instagram.com/newkenyatravel/"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fab fa-instagram"></i>
                      </Button>
                    </div> 
                                     
                    <Button 
                      onClick={contentScroll}
                      href='#content'
                      aria-label="contentDown" 
                      style={{background:'transparent'}} 
                      className='shadow-none'
                    >
                        <i className="now-ui-icons arrows-1_minimal-down" style={{fontSize:'50px'}}/>
                      </Button>
                    </Container>
                  </div>
                )}
                
              </CarouselItem>              
            )
          })}
        </Carousel>
      </div>
    </>
  );
}

export default LandingPageHeader;
