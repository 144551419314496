import DefaultFooter from 'components/Footers/DefaultFooter'
import LandingPageHeader from 'components/Headers/LandingPageHeader'
import ExamplesNavbar from 'components/Navbars/ExamplesNavbar'
import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import KTSFooter from 'components/Footers/KTSFooter'
import TSA1 from '../../../assets/img/KTSImages/Images/MsaTours/Classic5dayitineraryfromMombasa(3).JPG'
import TSA2 from '../../../assets/img/KTSImages/Images/MsaTours/MagicofTsavoandTaita(2).JPG'
import TSA3 from '../../../assets/img/KTSImages/Images/MsaTours/MagicofTsavoandTaita(3).JPG'
import TSA4  from '../../../assets/img/KTSImages/Images/MsaTours/MagicofTsavoandTaita.JPG'
import Conditions from '../../../assets/objects/TourConditions'

function TsavoAmboseli() {
    React.useEffect(()=>{
        window.scrollTo(0,0);
    })
    return (
        <div>
            <ExamplesNavbar/>
            <LandingPageHeader/>
            <div style={{marginLeft:'10%', marginRight:'10%', color:'black'}}>
                <h2 className='title text-center' id='content'>Tsavo and Amboseli</h2>
                <h3 className='title text-center'>3 nights, 4 days</h3>
                <h4 className='text-center'>Begins in Mombasa, Kenya</h4>
                <h5 className='text-center'>Available all year round</h5>
                <Row>
                    <h3 className='text-center'>An overview</h3>
                    <p className='text-justify'>
                        Would you like to visit the best Mombasa highlights, as well as a range of safari parks and
                        experience a range of leisure accommodation and hospitality?
                        <br/>
                        <br/>
                        From Mombasa, this is the perfect opportunity to experience the pleasure of The Big Five Safari! Drop us an
                        email/message to find out more!
                        <br/>
                        <br/>
                        Email: <a href="mailto:ptskenya@gmail.com">ptskenya@gmail.com</a> / <a href="mailto:info@kenyatoursafari.com">info@kenyatoursafari.com</a>
                        Social media: Follow us on Instagram as @newkenyatravel & Facebook: New Kenya Travel &
                        Tour Safaris LTD
                    </p>
                </Row>
                <Row>
                    <Col>
                        <img src={TSA1} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <img src={TSA4} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <img src={TSA3} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                </Row>
                <h3 className='title text-center'>Detailed View</h3>
                <Row>
                    <p classname='text-left'><b>Day 1: Mombasa - Tsavo East</b></p>
                    <p className='text-justify'>
                        Morning 0700hrs clients will be picked up from the hotel. Following a short briefing, depart for
                        Tsavo National Park, entering the park through Bachuma Gate. The park holds the country’s largest
                        elephant population, popularly known as the Red Elephant. The game drive meanders through the
                        vast plains interspersed with woodlands that behold captivating views. Exploring the hinterlands of
                        the park is a rewarding experience with opportunities of seeing Lions, Cheetahs, the great heards
                        of Buffaloes stampeding across the plains. Clients will arrive in time for lunch at the lodge.
                        Following lunch, enjoy some time at leisure, followed by an afternoon game drive in the park,
                        returning to the hotel as the sun sets in time for dinner and overnight.
                        <br/>
                        <b>Overnight in Tsavo on full board basis</b>
                    </p>
                    <p classname='text-left'><b>Day 2: Tsavo East - Amboseli</b></p>
                    <p className='text-justify'>
                        Following breakfast at the lodge, depart for Amboseli National Park, which is famous known for the
                        spectacular views of Mount Kilimanjaro, the highest free-standing mountain in Africa. Following
                        lunch at the lodge and a siesta, depart for an afternoon game drive, returning to the lodge as the
                        sun sets for dinner and overnight.
                        <br/>
                        <b>Overnight at a chosen accommodation in Amboseli on full board basis
                        Accommodation options: Amboseli Serena Lodge, Ol Tukai Lodge, Amboseli Sentrim, Amboseli
                        Kibo Camp, Elewana Tortillis Camp or similar</b>
                    </p>
                    <div>
                        <img src={TSA2} style={{marginBottom:'5%',width:'50%',marginLeft:'25%'}} className='img-raised'/>
                    </div>
                    <p classname='text-left'><b>Day 3: Amboseli National Park</b></p>
                    <p className='text-justify'>
                        Following breakfast, depart for a full day game drive in Amboseli National Park with picnic lunch
                        boxes. Return to the lodge late afternoon and enjoy the leisure facilities until dinner.
                        <b> Overnight at Amboseli on full board basis</b>
                    </p>
                    <p classname='text-left'><b>Day 4: Amboseli - Mombasa</b></p>
                    <p className='text-justify'>
                        Depart for an early morning game drive and return to the lodge for breakfast. After breakfast,
                        checkout and drive back to Mombasa arriving afternoon. Clients will be dropped off to their north
                        coast / south coast for onward arrangements (booked directly).
                    </p>
                    <br/>
                    <div>
                    <h3 className='title text-center'>End of Our Services</h3>
                    <br/>
                    <div>
                        <h4>Our Cost Includes:</h4>
                        <ul>
                            {Conditions.inclusion.map((item,i)=>(
                                <li key={i}>{item.value}</li>
                            ))}
                        </ul>
                
                        <h4>Our Cost Excludes:</h4>
                        <ul>
                        {Conditions.exclusion.map((item,i)=>(
                            <li key={i}>{item.value}</li>
                        ))}
                        </ul>
                        
                        <h4>Additional Remarks:</h4>
                        <ul>
                        {Conditions.remarks.map((item,i)=>(
                            <li key={i}>{item.value}</li>
                        ))}
                        </ul>
                        </div>
                    </div>
                </Row>
            </div>
            <div className='send-button text-center'>
                <Button
                    color='success'
                    href="/enquiries"
                >
                    Booking Enquiry
                </Button>
                <br/>
                <br/>
            </div>
            <KTSFooter/>
            <DefaultFooter/>
        </div>
    )
}

export default TsavoAmboseli;