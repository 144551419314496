import DefaultFooter from 'components/Footers/DefaultFooter'
import LandingPageHeader from 'components/Headers/LandingPageHeader'
import ExamplesNavbar from 'components/Navbars/ExamplesNavbar'
import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import KTSFooter from 'components/Footers/KTSFooter'
import C5D1 from '../../../assets/img/KTSImages/Images/MsaTours/Classic5dayItineraryfromMombasa.JPG'
import C5D2 from '../../../assets/img/KTSImages/Images/MsaTours/Classic5dayitineraryfromMombasa(1).JPG'
import C5D3 from '../../../assets/img/KTSImages/Images/MsaTours/Classic5dayitineraryfromMombasa(2).JPG'
import C5D4 from '../../../assets/img/KTSImages/Images/MsaTours/Classic5dayitineraryfromMombasa(3).JPG'
import Conditions from '../../../assets/objects/TourConditions';

function ClassicKenyaRev() {
    React.useEffect(()=>{
        window.scrollTo(0,0);
    })
    return (
        <div>
            <ExamplesNavbar/>
            <LandingPageHeader/>
            <div id='content' style={{color:'black'}}>
                <h2 className='title text-center'>Classic 5 Day Big Five Experience</h2>
                <h3 className="title text-center">5 day safari Available All year round</h3>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <Col sm='8' md='8' lg='8'>
                        <h3>An overview</h3>
                        <p>
                        Have you imagined a not-too-short, yet not-too-long safari in Kenya?
                        Would you like to visit a range of safari parks and experience a range of leisure accommodation
                        and hospitality?
                        <br/>
                        <br/>
                        Do you imagine viewing The Big Five in the most well-known National Parks in Kenya? Then this
                        is the perfect safari package for you!
                        <br/>
                        <br/>
                        This package includes some of the most well-known parks in Kenya, from Tsavo East & West, to
                        Amboseli National Park, beginning from and ending in Mombasa. Drop us an email/message to
                        find out more!
                        <br/>
                        <br/>
                        Email: <a href="mailto:ptskenya@gmail.com">ptskenya@gmail.com</a> / <a href="mailto:info@kenyatoursafari.com">info@kenyatoursafari.com</a>
                        Social media: Follow us on Instagram as @newkenyatravel & Facebook: New Kenya Travel &
                        Tour Safaris LTD
                        </p>
                        <br/>
                    </Col>
                    <Col style={{alignItems:'center'}} md='4' sm='4' lg='4'>
                        <img src={C5D1} style={{marginBottom:'10%'}} className='img-raised'/>
                        <img src={C5D2} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                </Row>
                
                <Row style={{marginLeft:'10%', marginRight:'10%', marginTop:'5%'}}>
                    <Col style={{alignItems:'center'}} md='4' sm='4' lg='4'>
                        <img src={C5D3} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <h3 className='text-center'>A detailed look</h3>
                        <p classname='text-left'><b>Day 1: Mombasa - Tsavo East</b></p>
                        <p className='text-justify'>
                            Morning 0700hrs clients will be picked up from the hotel. Following a short briefing, depart for
                            Tsavo National Park, entering the park through Bachuma Gate. The park holds the country’s largest
                            elephant population, popularly known as the Red Elephant. The game drive meanders through the
                            vast plains interspersed with woodlands that behold captivating views. Exploring the hinterlands of
                            the park is a rewarding experience with opportunities of seeing Lions, Cheetahs, the great heards
                            of Buffaloes stampeding across the plains. Clients will arrive in time for lunch at the lodge.
                            Following lunch, enjoy some time at leisure, followed by an afternoon game drive in the park,
                            returning to the hotel as the sun sets in time for dinner and overnight.
                            <br/>
                            <b>Overnight in Tsavo on full board basis</b>
                        </p>
                        
                    </Col>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <p classname='text-left'><b>Day 2: Tsavo East - Amboseli</b></p>
                    <p className='text-justify'>
                        Following breakfast at the lodge, depart for Amboseli National Park, which is famous known for the
                        spectacular views of Mount Kilimanjaro, the highest free-standing mountain in Africa. Following
                        lunch at the lodge and a siesta, depart for an afternoon game drive, returning to the lodge as the
                        sun sets for dinner and overnight.
                        <br/>
                        <b>Overnight in Amboseli on full board basis</b>
                    </p>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%', marginTop:'5%'}}>
                    <Col style={{alignItems:'center'}} md='4' sm='4' lg='4'>
                        <img src={C5D1} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <p classname='text-left'><b>Day 3: Amboseli National Park</b></p>
                        <p className='text-justify'>
                            Following breakfast, depart for a full day game drive in Amboseli National Park with picnic lunch
                            boxes. (Alternatively, morning game drive, return for lunch, followed by afternoon game drive).
                            Return to the lodge late afternoon and enjoy the leisure facilities until dinner.
                            <br/>
                            <b>Overnight in Amboseli on full board basis</b>
                        </p>
                    </Col>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <p classname='text-left'><b>Day 4: Amboseli - Taita Hills</b></p>
                    <p className='text-justify'>
                        Following an early morning game drive and breakfast, depart for Taita Hills and arrive in time for
                        lunch at Taita Hills Resort & Spa. Following lunch, enjoy a game drive en route to Salt Lick Lodge.
                        Dinner and overnight at the lodge.
                        <br/>   
                        <b>Overnight at Salt Lick Safari Lodge on full board basis</b>
                    </p>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <p classname='text-left'><b>Day 5: Taita Hills - Mombasa</b></p>
                    <p className='text-justify'>
                        Depart for an early morning game drive and return to the lodge for breakfast. After breakfast,
                        checkout and drive back to Mombasa arriving early afternoon. Clients will be dropped off to their
                        north coast / south coast hotel (on own arrangements).
                    </p>
                    <br/>
                    <br/>
                </Row>
                <div style={{marginLeft:'10%', marginRight:'10%'}}>
                    <h3 className='title text-center'>End of Our Services</h3>
                    <h4>Our Cost Includes:</h4>
                    <ul>
                        {Conditions.inclusion.map((item,i)=>(
                            <li key={i}>{item.value}</li>
                        ))}
                    </ul>
            
                    <h4>Our Cost Excludes:</h4>
                    <ul>
                    {Conditions.exclusion.map((item,i)=>(
                        <li key={i}>{item.value}</li>
                    ))}
                    </ul>
                    
                    <h4>Additional Remarks:</h4>
                    <ul>
                    {Conditions.remarks.map((item,i)=>(
                        <li key={i}>{item.value}</li>
                    ))}
                    </ul>
                </div>
            </div>
            <div className='send-button text-center'>
                <Button
                    color='success'
                    href="/enquiries"
                >
                    Booking Enquiry
                </Button>
            </div>
            <KTSFooter/>
            <DefaultFooter/>
        </div>
    )
}

export default ClassicKenyaRev;