/*eslint-disable*/
import React from "react";

// reactstrap components
import { Col, Container, Nav, Row } from "reactstrap";

import ktsLogo from '../../assets/img/kts_logo_old.png'
import katbond from '../../assets/img/KATBonded.jpg'

function KTSFooter() {
  return (
    <footer className='footer' data-background-color="black">
        <Container fluid>
            <Row style={{marginLeft:'5%', marginRight:'5%',display:"inline-flex"}}>
                <Col md='8' sm='8' lg='8'>
                    <Row style={{display:"inline-flex"}}>
                        <a href='/home'>
                            <img src={ktsLogo}/>
                        </a>
                    </Row>    
                    <p style={{marginRight:'20%', marginTop:'5%'}}>
                        Explore the Wildlife and Culture, and experience the scenic Nature
                        and hospitality of Kenya as you journey through the Mountains, Lakes,
                        vast Plains and Platues all the way down to the beaches along the coast.
                    </p>
                    <h5>We are proud members of ...</h5>
                    <img src={katbond} style={{maxHeight:'50px', height:'auto'}}/>
                </Col>
                <Col md='4' sm='4' lg='4'>
                        <h4 className="title" style={{marginTop:'-10%'}} id='contact-us'>
                            Contact Info
                        </h4>
                        <li style={{listStyle:'none'}}>
                            <ul>
                                <a>Nyali Road, Mombasa</a>
                            </ul>
                            <ul>Kenya</ul>
                            <ul>
                                <a href='tel:+254734414907'>+254 734 414907</a> | <a href='tel:+254722414907'>+254 722 414907</a>
                            </ul>
                            <ul>
                                <a href='mailto:info@kenyatoursafaris.com'>info@kenyatoursafaris.com</a>
                            </ul>
                        </li>  
                    <div>
                        <h4 className="title" style={{marginTop:'-5%'}}>Follow Us on...</h4>
                        <Nav>
                            <Row className="">
                                <Col>
                                    <a href="https://www.facebook.com/newkenyatour18/" target="_blank"><i className="fab fa-facebook-square"></i></a>
                                </Col>
                                <Col>
                                    <a href="https://www.instagram.com/newkenyatravel/" target="_blank"><i className="fab fa-instagram"></i></a>
                                </Col>
                            </Row>
                        </Nav>
                    </div>
                </Col>
            </Row>
        </Container>
              
    </footer>
  );
}

export default KTSFooter;
