import React from 'react'
import { Col, Row } from 'reactstrap'

function Testimonials() {

    return (
        <div>
            <h2 className='title text-center'>Testimonials</h2>
            <Row style={{paddingLeft:'5%', paddingRight:'5%'}}>
                <Col md='4'>
                    <div className="" >
                    <blockquote>
                        <p className="blockquote blockquote-info" style={{borderColor:'#18ce0f',color:'#888888', borderRadius:'5px',maxHeight:'450px', minHeight:'250px'}}>
                        "The vacation was one of the most memorable ones! (Masai Mara, Rift Valley, Nairobi, Tsavo Taita Hills). We had
                        the pleasure to have Robert as our driver guide - he is an extremely well-experienced and professional tour representative
                        for Kenya and the company. His knowledge was amazing."{" "}
                        <br/>
                        <small style={{color:'#00000080', fontWeight:'bold'}}>- Mr. Gurung</small>
                        </p>
                    </blockquote>
                    </div>
                </Col>
                <Col md='4'>
                    <div className="" >
                    <blockquote>
                        <p className="blockquote blockquote-info" style={{borderColor:'#18ce0f',color:'#888888', borderRadius:'5px',maxHeight:'450px', minHeight:'250px'}}>
                        "The lodges had good facilities and service was pretty good. The dream of viewing the big five was also fulfilled. 
                        Highly recommend to book with the company. They provide very friendly services during booking process. The driver 
                        guides are also polite, and knowledgeable."{" "}
                        <br/>
                        <small style={{color:'#00000080', fontWeight:'bold'}}>- Vivek Bhundia</small>
                        </p>
                    </blockquote>
                    </div>
                </Col>
                <Col md='4'>
                    <div className="">
                    <blockquote >
                        <p className="blockquote blockquote-info" style={{borderColor:'#18ce0f',color:'#888888', borderRadius:'5px', maxHeight:'450px', minHeight:'250px'}}>
                        "Great service, I loved every bit of the trip. There were beautiful sceneries, the vehicle was comfotable & 
                        clean and the driver's very friendly. I would recommend all of my friends to join their safari and definitely 
                        planning on going again."{" "}
                        <br/>
                        <br/>
                        <small style={{color:'#00000080', fontWeight:'bold'}}>- Sahil Patel</small>
                        </p>
                    </blockquote>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Testimonials