/*eslint-disable*/
import React, { useState } from "react";

// reactstrap components
import { Container, UncontrolledTooltip } from "reactstrap";

// core components

function DefaultFooter() {
  const [copied,setCopied] = useState(false);
  const email = "sales@sysec.online";

  const copier = () => {
    setCopied(true)
    navigator.clipboard.writeText(email);

  }

  return (
    <>
      <footer className="footer footer-default">
        <Container>
          <div className="text-center" id="copyright">
            © {new Date().getFullYear()}. Designed by{" "}
            <a
              target="_blank"
            >
              SySec
            </a>
            . Need a Site?{" "}
            <a
              onClick={copier}
              id="mailingtip"
            >
              Email SySec
            </a>
            .
            <UncontrolledTooltip target="#mailingtip">
              {copied === true && (
                "Copied"
              )}
              {copied === false && (
                "Click to Copy Email"
              )}
            </UncontrolledTooltip>
            
          </div>
        </Container>
      </footer>
    </>
  );
}

export default DefaultFooter;
