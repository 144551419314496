import DefaultFooter from 'components/Footers/DefaultFooter'
import LandingPageHeader from 'components/Headers/LandingPageHeader'
import ExamplesNavbar from 'components/Navbars/ExamplesNavbar'
import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import KTSFooter from 'components/Footers/KTSFooter'
import TGS1 from '../../../assets/img/KTSImages/Images/NrbTours/TheGrandSafari.JPG'
import TGS2 from '../../../assets/img/KTSImages/Images/NrbTours/ClassicPackage.JPG'
import TGS3 from '../../../assets/img/KTSImages/Images/NrbTours/TheGrandSafari(2).JPG'
import TGS4 from '../../../assets/img/KTSImages/Images/NrbTours/TheGrandSafari(3).JPG'
import Conditions from '../../../assets/objects/TourConditions';


function GrandSafari() {
    React.useEffect(()=>{
        window.scrollTo(0,0);
    })
    return (
        <div>
            <ExamplesNavbar/>
            <LandingPageHeader/>
            <div style={{marginLeft:'10%', marginRight:'10%'}}>
                <h2 className='title text-center mb-0' id='content'>The Grand Safari</h2>
                <h3 className='text-center'>9 nights, 10 days</h3>
                <h4 className='text-center'>Available all year round</h4>
                <Row>
                    <h3 className='text-center'>An overview</h3>
                    <p className='text-justify'>
                        Are you in for a lifetime of an adventure to Kenya, known for its well-known surprises in the
                        wilderness?
                        <br/>
                        <br/>
                        Do you dream of experiencing a fresh and unique twist to an African safari with its culture, history
                        and wildlife? Then this is the perfect safari package for you! This package includes some of the most well-known parks in Kenya, from Samburu National
                        Reserve, Lake Nakuru, to Masai Mara National Reserve and Amboseli National Park, beginning
                        from and ending in Nairobi. Drop us an email/message to find out more!
                        <br/>
                        <br/>
                        Email: <a href="mailto:ptskenya@gmail.com">ptskenya@gmail.com</a> / <a href="mailto:info@kenyatoursafari.com">info@kenyatoursafari.com</a>
                        Social media: Follow us on Instagram as @newkenyatravel & Facebook: New Kenya Travel &
                        Tour Safaris LTD
                        <br/>
                        <br/>
                    </p>
                </Row>
                <Row>
                    <Col>
                        <img src={TGS1} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <img src={TGS2} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <img src={TGS3} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                </Row>
                <Row>
                    <h3 className='title text-center'>Detailed View</h3>
                </Row>
                <Row>
                    <p classname=''><b>Day 1: Nairobi</b></p>
                    <p className='text-justify'>
                        On arrival into Nairobi, clients transferred on private basis to the hotel.
                        <br></br>
                        <b> Overnight in Nairobi on full board basis.</b>
                        <b> Accommodation options: Eka Hotel, 2.5.4 Ole Sereni Hotel, Four Points Sheraton Airport hotel,
                        Sarova Stanley.</b>
                    </p>
                    <p classname='text-left'><b>Day 2: Nairobi - Samburu national Reserve</b></p>
                    <p className='text-justify'>
                        0700HRS after breakfast in Nairobi and a short briefing, depart for Samburu National Reserve,
                        arriving in time for lunch. Following lunch, depart for your first afternoon game drive. Some of
                        Kenya’s rarest species like the long necked gerenuk, Grevy’s Zebras, reticulated giraffe, beisa oryx
                        and blue shanked Somali Ostrich can be seen here. Because of the sheer rarity and beauty of the
                        rugged terrain, you spend two nights here.
                        <br></br>
                        <b> Overnight in Samburu National Reserve on full board basis.</b>
                        <b> Accommodation options: Samburu Intrepids, Samburu Elephant Camp, Samburu Simba, Larsens
                        Tented Camp, or similar</b>
                    </p>
                    <img src={TGS4 } style={{marginBottom:'5%',width:'50%',marginLeft:'25%'}} className='img-raised'/>
                    <p classname='text-left'><b>Day 3: Samburu National Reserve</b></p>
                    <p className='text-justify'>
                        Morning and afternoon game drives in the reserve.
                        <br/>
                        <b> Overnight in Samburu National Reserve on full board basis</b>
                    </p>
                    <p classname='text-left'><b>Day 4: Samburu – Lake Nakuru</b></p>
                    <p className='text-justify'>
                        Leaving Samburu for lake Nakuru after breakfast, proceed to Lake Nakuru National Park, home of
                        the famous, millions of seasonal pink Flamingoes and other aquatic birds. Other residents include
                        buffalo, Rothschild giraffe, eland, lions, leopards, and black rhino. It goes without saying that this
                        lush environment is most definitely a bird watches paradise. Arrive in time for lunch. After lunch,
                        depart for an afternoon game drive, returning to the lodge for dinner and overnight.
                        <br/>
                        <b> Overnight in Lake Nakuru on full board basis.</b>
                        <b> Accommodation options: Lake Nakuru Lodge, Sarova Lionhill Camp, Lake Nakuru Sopa Lodge</b>
                    </p>
                    <p classname='text-left'><b>Day 5: Lake Nakuru – Masai Mara</b></p>
                    <p className='text-justify'>
                        Following an early morning breakfast, depart for Masai Mara National Reserve, one of East Africa’s
                        most famous reserve. The Mara consists of undulating hills and great rolling grasslands that sustain
                        some of the most varied wildlife in Africa. Have lunch at the lodge and some leisure time. Shortly
                        after, depart for an afternoon game drive, returning to the lodge late afternoon. Enjoy some
                        leisure facilities at the lodge/camp, followed by dinner and overnight.
                        <br/>
                        <b> Overnight in Masai Mara on full board basis.</b>
                        <b> Accommodation options: Mara Serena Lodge, Sarova Mara Game Camp, Mara Sopa lodge,
                        Keekorok Lodge, Ashnil Mara Camp, Mara Treetops, Mara Fig Tree, Mara Sentrim Camp, Mara
                        Simba Lodge, Zebra Plains Mara Camp, Elewana Sand Rivers Camp or similar.</b>
                    </p>
                    <p classname='text-left'><b>Day 6: Full-day game drive in Masai Mara</b></p>
                    <p className='text-justify'>
                        After breakfast, depart for full day game drive in Masai Mara game reserve with packed lunch
                        boxes. You will have the opportunity to see Mara river, a river in Mara Region in Tanzania and
                        Narok County in Kenya, and lies across the migration path of ungulates in the Serengeti/Masai
                        Mara game reserves. Hippopotami and Nile crocodiles are found in large groups in the Mara and
                        Talek rivers. Leopards, hyenas, cheetahs, jackals, and bat-eared foxes can also be found in the
                        reserve. The plains between the Mara River and the Esoit Siria Escarpment are probably the best
                        area for game viewing, in particular regarding lion and cheetah.
                        Return to the lodge/camp late afternoon for dinner.
                        <br/>
                        <b> Overnight in Masai Mara on full board basis</b>
                    </p>
                    <p classname='text-left'><b>Day 7: Masai Mara – Nairobi</b></p>
                    <p className='text-justify'>
                        Following breakfast this morning, drive towards Nairobi, arriving late afternoon. Check in at a
                        Nairobi hotel, with the remainder of the afternoon at leisure. Dinner at Carnivore Restaurant /
                        Haandi Restaurant.
                        <br/>
                        <b> Overnight in Nairobi on full board basis.</b>
                        <b> Accommodation options: Eka Hotel, Ole Sereni Hotel, Four Points Sheraton Airport hotel,
                        Sarova Stanley.</b>
                    </p>
                    <p classname='text-left'><b>Day 8: Nairobi – Amboseli</b></p>
                    <p className='text-justify'>
                        Following breakfast this morning, depart for Amboseli National park, arriving in time for lunch.
                        Amboseli National Park is famous known for the spectacular views of Mount Kilimanjaro, the
                        highest free-standing mountain in Africa. Following lunch at the lodge and a siesta, depart for an
                        afternoon game drive, returning to the lodge as the sun sets for dinner and overnight.
                        <br/>
                        <b> Overnight at a chosen accommodation in Amboseli on full board basis.</b>
                        <b> Accommodation options: Amboseli Serena Lodge, Ol Tukai Lodge, Amboseli Sentrim, Amboseli
                        Kibo Camp, Elewana Tortillis Camp or similar.</b>
                    </p>
                    <p classname='text-left'><b>Day 9: Amboseli National Park</b></p>
                    <p className='text-justify'>
                        Following breakfast, depart for a full day game drive in Amboseli National Park with picnic lunch
                        boxes. Return to the lodge late afternoon and enjoy the leisure facilities until dinner.
                        <br/>
                        <b> Overnight at a chosen accommodation in Amboseli on full board basis.</b>
                    </p>
                    <p classname='text-left'><b>Day 10: Amboseli National Park - Nairobi</b></p>
                    <p className='text-justify'>
                        After breakfast, depart for Nairobi, arriving in the city early afternoon. On arrival, clients will be
                        dropped off at Nairobi airport or Nairobi hotel for their onward arrangements.
                    </p>
                </Row>
                <Row>
                    <h3 className='title text-center'>End of Our Services</h3>
                </Row>
                <h4>Our Cost Includes:</h4>
                <ul>
                    {Conditions.inclusion.map((item,i)=>(
                        <li key={i}>{item.value}</li>
                    ))}
                </ul>
        
                <h4>Our Cost Excludes:</h4>
                <ul>
                {Conditions.exclusion.map((item,i)=>(
                    <li key={i}>{item.value}</li>
                ))}
                </ul>
                
                <h4>Additional Remarks:</h4>
                <ul>
                {Conditions.remarks.map((item,i)=>(
                    <li key={i}>{item.value}</li>
                ))}
                </ul>

            </div>            
            <div className='send-button text-center'>
                <Button
                    color='success'
                    href="/enquiries"
                >
                    Booking Enquiry
                </Button>
                <br/>
                <br/>
            </div>
            <KTSFooter/>
            <DefaultFooter/>
        </div>
    )
}

export default GrandSafari;