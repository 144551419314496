import React, { useState } from 'react';
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown
} from 'reactstrap';

function DDMSubItem(props){
  const [isOpen, setIsOpen] = useState(false);
  const [subIsOpen, setSubIsOpen] = useState(false);
  const [selectedHeader, setSelectedHeader] = useState(null);

  const toggle = () => setIsOpen(!isOpen);

  const handleHeaderClick = (header) => {
    setSelectedHeader(header);
    setIsOpen(true);
    setSubIsOpen(!subIsOpen)
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
    setSubIsOpen(false);
  }

  const menuItems = props.headers;
  const buttonTitle = props.buttonTitle;

  return (
    <>
    <UncontrolledDropdown 
      isOpen={isOpen} 
      onMouseEnter={toggle} 
      onMouseLeave={()=>handleMouseLeave()}
    >
      <DropdownToggle 
        aria-haspopup={true}
        caret
        color="default"
        href="#"
        nav
      >
        {buttonTitle}
      </DropdownToggle>
      {isOpen &&(
          <div>
            <DropdownMenu className='dropdown-menu'>
              {menuItems.map((header) => (
                <React.Fragment key={header.name}>
                  <DropdownItem onClick={() => handleHeaderClick(header)}>
                  <li>
                    <a
                      header
                      href={header.url}
                    >
                      <p style={{color:'black'}}>
                        {header.name}
                        <i className='now-ui-icons arrows-1_minimal-down' style={{color:'black', marginLeft:'10px'}}></i>
                      </p>
                    </a>
                  </li>
                  </DropdownItem>
                  {selectedHeader && selectedHeader.name === header.name && subIsOpen &&
                    header.items.map((item) => (
                      <DropdownItem key={item.name} href={item.url} style={{color:'#00000095'}}>
                        {item.name}
                      </DropdownItem>
                    ))}
                </React.Fragment>
              ))}
            </DropdownMenu>
          </div>
      )}
      
    </UncontrolledDropdown>
    </>
  );
};

export default DDMSubItem;
