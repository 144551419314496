import DefaultFooter from 'components/Footers/DefaultFooter'
import LandingPageHeader from 'components/Headers/LandingPageHeader'
import ExamplesNavbar from 'components/Navbars/ExamplesNavbar'
import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import KTSFooter from 'components/Footers/KTSFooter'
import C2C1 from '../../../assets/img/KTSImages/Images/MsaTours/MagicofTsavoandTaita.JPG'
import C2C2 from '../../../assets/img/KTSImages/Images/MsaTours/CoasttoCitySafariPackage(1).JPG'
import C2C3 from '../../../assets/img/KTSImages/Images/MsaTours/CoasttoCitySafariPackage(2).JPG'
import Conditions from '../../../assets/objects/TourConditions'


function CoastToCity() {
    React.useEffect(()=>{
        window.scrollTo(0,0);
    })
    return (
        <div>
            <ExamplesNavbar/>
            <LandingPageHeader/>
            <div style={{marginLeft:'10%', marginRight:'10%'}}>
                <h2 className='title text-center' id='content'>Coast to City Safari</h2>
                <h3 className='title text-center'>6 nights, 7 days - Extra excursions Incl.</h3>
                <h4 className='text-center'>Begins in Mombasa, Kenya</h4>
                <h5 className='text-center'>Available all year round</h5>
                
                <Row>
                    <h3 className='text-center'>An overview</h3>
                    <p className='text-justify'>
                        Would you like to visit the best Mombasa highlights, as well as a range of safari parks and
                        experience a range of leisure accommodation and hospitality?
                        <br/>
                        <br/>
                        This package includes some of the most well-known parks in Kenya, from Tsavo East National Park,
                        to Amboseli National Park and Masai Mara National Reserve, beginning from Mombasa and ending
                        in Nairobi. Drop us an email/message to find out more!
                        <br/>
                        <br/>
                        Email: <a href="mailto:ptskenya@gmail.com">ptskenya@gmail.com</a> / <a href="mailto:info@kenyatoursafari.com">info@kenyatoursafari.com</a>
                        Social media: Follow us on Instagram as @newkenyatravel & Facebook: New Kenya Travel &
                        Tour Safaris LTD
                    </p>
                </Row>
                <Row>
                    <Col>
                        <img src={C2C1} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <img src={C2C2} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <img src={C2C3} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                </Row>
                <h3 className='title text-center'>Detailed View</h3>
                <Row>
                    
                    <p classname='text-left'><b>Day 1: Mombasa - Tsavo East</b></p>
                    <p className='text-justify'>
                        Morning 0700hrs clients will be picked up from the hotel. Following a short briefing, depart for
                        Tsavo National Park, entering the park through Bachuma Gate. The park holds the country’s largest
                        elephant population, popularly known as the Red Elephant. The game drive meanders through the
                        vast plains interspersed with woodlands that behold captivating views. Exploring the hinterlands of
                        the park is a rewarding experience with opportunities of seeing Lions, Cheetahs, the great heards
                        of Buffaloes stampeding across the plains. Clients will arrive in time for lunch at the lodge.
                        Following lunch, enjoy some time at leisure, followed by an afternoon game drive in the park,
                        returning to the hotel as the sun sets in time for dinner and overnight.
                        <b> Overnight at an Aruba Ashnil Lodge (or similar) in Tsavo on full board basis</b>
                    </p>
                    <p classname='text-left'><b>Day 2: Tsavo - Amboseli</b></p>
                    <p className='text-justify'>
                        Following breakfast at the lodge, depart for Amboseli National Park Morning breakfast at the lodge.
                        Check out from the lodge and depart for Amboseli National Park, which is famous known for the
                        spectacular views of Mount Kilimanjaro, the highest free-standing mountain in Africa. Following
                        lunch at the lodge and a siesta, depart for an afternoon game drive, returning to the lodge as the
                        sun sets for dinner and overnight.
                        <b> Overnight at Ol Tukai Lodge / Amboseli Serena (or similar) on full board basis</b>
                    </p>
                    <div>
                        <img src={C2C2} style={{marginBottom:'5%',width:'50%',marginLeft:'25%'}} className='img-raised'/>
                    </div>
                    <p classname='text-left'><b>Day 3: Amboseli National Park</b></p>
                    <p className='text-justify'>
                        Following breakfast, depart for a full day game drive in Amboseli National Park with picnic lunch
                        boxes. Return to the lodge late afternoon and enjoy the leisure facilities until dinner.
                        <b> Overnight at Ol Tukai Lodge / Amboseli Serena (or similar) on full board basis</b>
                    </p>
                    <p classname='text-left'><b>Day 4: Amboseli - Lake Nakuru</b></p>
                    <p className='text-justify'>
                        After breakfast, check out and depart for Lake Nakuru. En route stop in Nairobi for lunch. Clients
                        will arrive Lake Nakuru late afternoon and enjoy a game drive en route to the lodge, arriving in
                        time for dinner.
                        <b> Overnight at Lake Nakuru Sopa Lodge / Sarova Lion Hill / Lake Nakuru Lodge (or similar) on full board basis</b>
                    </p>
                    <p classname='text-left'><b>Day 5: Lake Nakuru – Masai Mara</b></p>
                    <p className='text-justify'>
                        Following breakfast, depart for Masai Mara National Reserve, arriving in time for lunch. Masai
                        Mara, one of the most famous and important wildlife conservation and wilderness areas in Africa,
                        is world-renowned for its exceptional populations of Lion, African Leopard, Cheetah and African
                        Bush Elephant. Following lunch and perhaps a siesta, depart for an afternoon game drive,
                        returning to the lodge as the sun sets.
                        <b> Overnight at Masai Mara Sopa Lodge (or similar) on full board basis</b>
                    </p>
                    <p classname='text-left'><b>Day 6: Masai Mara National Reserve</b></p>
                    <p className='text-justify'>
                        After breakfast, depart for full day game in Masai Mara National Reserve with picnic lunch boxes.
                        Return to lodge late afternoon. Enjoy leisure facilities. Dinner and overnight at Mara Sopa Lodge
                        (or similar) on full board basis.
                    </p>
                    <p classname='text-left'><b>Day 7: Masai Mara – Nairobi</b></p>
                    <p className='text-justify'>
                        After breakfast, depart for Nairobi, arriving in the afternoon. Once in the city, clients will be
                        dropped off to a Nairobi Hotel.
                    </p>
                    <div>
                    <h3 className='text-center title'>End of Our Services</h3>
                    <div>
                        <h4>Our Cost Includes:</h4>
                        <ul>
                            {Conditions.inclusion.map((item,i)=>(
                                <li key={i}>{item.value}</li>
                            ))}
                        </ul>
                
                        <h4>Our Cost Excludes:</h4>
                        <ul>
                        {Conditions.exclusion.map((item,i)=>(
                            <li key={i}>{item.value}</li>
                        ))}
                        </ul>
                        
                        <h4>Additional Remarks:</h4>
                        <ul>
                        {Conditions.remarks.map((item,i)=>(
                            <li key={i}>{item.value}</li>
                        ))}
                        </ul>
                    </div>
                    </div>
                </Row>
            </div>
            <div className='send-button text-center'>
                <Button
                    color='success'
                    href="/enquiries"
                >
                    Booking Enquiry
                </Button>
            </div>
            <KTSFooter/>
            <DefaultFooter/>
        </div>
    )
}

export default CoastToCity;