import DefaultFooter from 'components/Footers/DefaultFooter'
import LandingPageHeader from 'components/Headers/LandingPageHeader'
import ExamplesNavbar from 'components/Navbars/ExamplesNavbar'
import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import KTSFooter from 'components/Footers/KTSFooter'
import TPT1 from '../../assets/img/KTSImages/Images/Gallery/Gallery24.JPG';
import TPT2 from '../../assets/img/KTSImages/Images/Gallery/Gallery20.JPG';
import TPT3 from '../../assets/img/KTSImages/Images/Gallery/Gallery21.JPG';
import TPT4 from '../../assets/img/KTSImages/Images/Gallery/Gallery16.JPG';
import TPT5 from '../../assets/img/KTSImages/Images/Gallery/Gallery14.JPG';
import Conditions from '../../assets/objects/TourConditions';


function TzPackaged() {
    React.useEffect(()=>{
        window.scrollTo(0,0);
    })
    return (
        <div>
            <ExamplesNavbar/>
            <LandingPageHeader/>
            <div style={{marginLeft:'10%', marginRight:'10%'}}>
                <h2 className='title text-center mb-0' id='content'>Tanzania Packaged Tour</h2>
                <h3 className='title text-center'>8 nights, 9 days</h3>
                <h4 className='text-center'>Available All year Round</h4>
                <Row>
                    <h3 className='text-center'>An overview</h3>
                    <p className='text-justify'>
                        Drop us an email/message to find out more!
                        <br/>
                        <br/>
                        Email: <a href="mailto:ptskenya@gmail.com">ptskenya@gmail.com</a> / <a href="mailto:info@kenyatoursafari.com">info@kenyatoursafari.com</a>
                        Social media: Follow us on Instagram as @newkenyatravel & Facebook: New Kenya Travel &
                        Tour Safaris LTD
                        <br/>
                        <br/>
                    </p>
                </Row>
                <Row>
                    <Col>
                        <img src={TPT1} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <img src={TPT2} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <img src={TPT4} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                </Row>
                <Row>
                    <h3 className='title text-center'>Detailed View</h3>
                </Row>
                <Row>
                    <p classname=''><b>Day 1: Arusha</b></p>
                    <p className='text-justify'>
                        On arrival into Arusha, clients met and transferred on private basis to their Arusha Hotel.
                        Remainder of the day at leisure.
                        <b> Overnight in Arusha on full board basis</b>
                        <br/>
                        <b>Accommodation options: Arusha Coffee Lodge, Arusha Serena Hotel, Four Points by Sheraton
                        Arusha, Gran Melia, Rivertrees Country Inn, Ilboru Safari Lodge.</b>
                        <b> Optional excursions: Arusha Half-day city tour, full-day city tour, N’giresi Village cultural visit</b>
                    </p>
                    <p classname='text-left'><b>Day 2: Arusha - Lake Manyara</b></p>
                    <p className='text-justify'>
                        Following breakfast and a short briefing, clients will depart for Lake Manyara National Park,
                        arriving in time for lunch. Following lunch, proceed for an afternoon game drive, returning to the
                        lodge / camp in time for dinner and overnight.
                        <b> Overnight in Lake Manyara on full board basis.</b>
                        <b> Accommodation options: Lake Manyara Serena lodge, Lake Manyara Wildlife Camp, Lake
                        Manyara Kilimamoja, Maramboi Tented Lodge, Burunge Tented Camp</b>
                    </p>
                    <p classname='text-left'><b>Day 3: Lake Manyara - Serengeti</b></p>
                    <p className='text-justify'>
                        Following breakfast, drive across the Ngorongoro Conservation area and into Serengeti National
                        Park. Arrive the lodge in time for lunch. After lunch, depart for an afternoon game drive, returning
                        to the lodge / camp as the sun sets.
                        <b> Overnight in Serengeti on full board basis.</b>
                        <b> Accommodation options: Serengeti Sopa Lodge, Serengeti Serena Lodge, Kati Kati Camp, Lemala
                        Ewanjan Tented camp, Lemala Nanyukie tented lodge, Kubu Kubu Tented Lodge, Ole Serai Turner
                        Springs / Moru Kopjes / Seronera, Four Seasons Serengeti Lodge, Serengeti Simba Lodge, Mbuzi
                        Mawe tented lodge, Elewana Migration / Pioneer Camp.</b>
                    </p>
                    <p classname='text-left'><b>Day 4: Serengeti National Park</b></p>
                    <p className='text-justify'>
                        Morning and afternoon game drives in Serengeti National Park. <b>Overnight as a chosen accommodation in Serengeti</b>
                    </p>
                    <p classname='text-left'><b>Day 5: Serengeti National Park</b></p>
                    <p className='text-justify'>
                        Morning and afternoon game drives in Serengeti National Park. <b>Overnight as a chosen accommodation in Serengeti</b>
                    </p>
                    <p classname='text-left'><b>Day 6: Serengeti - Ngorongoro</b></p>
                    <p className='text-justify'>
                        Following a morning game drive and breakfast, depart for Ngorongoro with picnic lunch boxes.
                        Arrive in the afternoon, with the remainder of the evening at leisure.
                        <b> Overnight in Ngorongoro / Karatu on full board basis</b>
                        <b> Accommodation options: Ngorongoro Serena Lodge, Ngorongoro Sopa Lodge, Ngorongoro Farm
                        house, Ngorongoro Farmhouse Valley, Tloma Lodge, Ngorongoro Oldeani Mountain Lodge, Gibbs
                        Farm, Neptune Ngorongoro, Lemala Ngorongoro Lodge</b>
                    </p>
                    <div>
                        <p classname='text-left'><b>Day 7: Ngorongoro Crater</b></p>
                        <p className='text-justify'>
                            Depart on a full day of game viewing in the crater with picnic lunch boxes, returning to the lodge
                            late afternoon.
                            <b>Overnight at a chosen accommodation in Ngorongoro / Karatu.</b>
                        </p>
                        <p classname='text-left'><b>Day 8: Ngorongoro - Tarangire National Park</b></p>
                        <p className='text-justify'>
                            Following breakfast, depart for Tarangire National Park, arriving in time for lunch. Following lunch,
                            proceed for an afternoon game drive in Tarangire National Park, returning in time for dinner at the
                            camp / lodge.
                            <b> Overnight in Tarangire National Park.</b>
                            <b> Accommodation options: Tarangire Sopa Lodge, Lemala Mpingo Ridge, Mbali Mbali Tarangire
                            River Camp, Tarangire Kuro Treetops, Tarangire Simba Lodge</b>
                        </p>
                        <p classname='text-left'><b>Day 9: Tarangire - Arusha</b></p>
                        <p className='text-justify'>
                            Following breakfast, depart for Arusha, arriving in time for lunch at Arusha Coffee Lodge or similar.
                            After lunch, clients will be dropped off to Arusha hotel or Arusha Airport / Kilimanjaro
                            International Airport for onwards arrangements.
                        </p>
                    </div>
                </Row>
                <Row>
                    <h3 className='title text-center'>End of Our Services</h3>
                </Row>
                <h4>Our Cost Includes:</h4>
                <ul>
                    {Conditions.inclusionTZ.map((item,i)=>(
                        <li key={i}>{item.value}</li>
                    ))}
                </ul>
        
                <h4>Our Cost Excludes:</h4>
                <ul>
                {Conditions.exclusion.map((item,i)=>(
                    <li key={i}>{item.value}</li>
                ))}
                </ul>
                
                <h4>Additional Remarks:</h4>
                <ul>
                {Conditions.remarks.map((item,i)=>(
                    <li key={i}>{item.value}</li>
                ))}
                </ul>

            </div>            
            <div className='send-button text-center'>
                <Button
                    color='success'
                    href="/enquiries"
                >
                    Booking Enquiry
                </Button>
            </div>
            <KTSFooter/>
            <DefaultFooter/>
        </div>
    )
}

export default TzPackaged;