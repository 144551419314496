import React from "react";
import ReactDOM from "react-dom/client"
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";

import Home from "./Home";
import AboutUs from "AboutUs";
import Enquiries from "Enquiries";
import Kenya from "Tours/Kenya";
import Tanzania from "Tours/Tanzania";
import CoastToCity from "Tours/Kenya Tours/Mombasa/Coast to City";
import MsaGetaway from "Tours/Kenya Tours/Mombasa/Mombasa Getaway";
import TsavoAmboseli from "Tours/Kenya Tours/Mombasa/Tsavo Amboseli";
import TsavoTaita from "Tours/Kenya Tours/Mombasa/Tsavo Taita";
import BeachWildlife from "Tours/Kenya Tours/Mombasa/Beach and Wildlife";
import ClassicKenya from "Tours/Kenya Tours/Mombasa/Classic 5 Day";
import ClassicKenyaRev from "Tours/Kenya Tours/Mombasa/Classic 5 Day Rev";
import AuthSafari from "Tours/Kenya Tours/Nairobi/Authentic Safari";
import ClassicKenyaNbo from "Tours/Kenya Tours/Nairobi/Classic Package";
import GrandSafari from "Tours/Kenya Tours/Nairobi/Grand Safari";
import KenyanSafariNbo from "Tours/Kenya Tours/Nairobi/Kenyan Safari";
import RedMudNbo from "Tours/Kenya Tours/Nairobi/Red Mud";
import MaraNakuruNbo from "Tours/Kenya Tours/Nairobi/Masaai mara Nakuru";
import AmboseliNgorongoro from "Tours/Kenya-Tanzania Tours/Amboseli Ngorongoro";
import SecretsofEALong from "Tours/Kenya-Tanzania Tours/Secrets of Africa Long";
import SecretsOfEA from "Tours/Kenya-Tanzania Tours/Secrets of Africa";
import ManyaraSerengetiNgoro from "Tours/Tanzania Tours/Manyara Serengeti Ngorongoro";
import Ngorongoro from "Tours/Tanzania Tours/Ngorongoro";
import SerengetiFlyIn from "Tours/Tanzania Tours/Serengeti FlyIn";
import TzPackaged from "Tours/Tanzania Tours/Tanzania Packaged Tour";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Switch>
      <Switch>
        <Route path="/index" render={(props) => <Home {...props} />} />
        <Route
          path="/about-us"
          render={(props)=> <AboutUs {...props} />}
        />
        <Route
          path="/enquiries"
          render={(props)=> <Enquiries {...props} />}
        />
        <Route
          path="/kenya-locations"
          render={(props)=> <Kenya {...props} />}
        />
        <Route
          path="/tanzania-locations"
          render={(props)=><Tanzania {...props}/>}
        />

        <Route
          path="/kenya/mombasa/beach-and-wildlife"
          render={(props)=> <BeachWildlife {...props}/>}
        />
        <Route
          path="/kenya/mombasa/classic-tour"
          render={(props)=> <ClassicKenya {...props}/>}
        />
        <Route
          path="/kenya/mombasa/classic-tour-reverse"
          render={(props)=> <ClassicKenyaRev {...props}/>}
        />
        <Route
          path="/kenya/mombasa/coast-to-city"
          render={(props)=> <CoastToCity {...props}/>}
        />
        <Route
          path="/kenya/mombasa/mombasa-getaway"
          render={(props)=> <MsaGetaway {...props}/>}
        />
        <Route
          path="/kenya/mombasa/tsavo-to-amboseli"
          render={(props)=> <TsavoAmboseli {...props}/>}
        />
        <Route
          path="/kenya/mombasa/tsavo-to-taita"
          render={(props)=> <TsavoTaita {...props}/>}
        />

        <Route 
          path="/kenya/nairobi/authentic-safari"
          render={(props)=> <AuthSafari {...props}/>}
        />
        <Route 
          path="/kenya/nairobi/classic-package"
          render={(props)=> <ClassicKenyaNbo {...props}/>}
        />
        <Route 
          path="/kenya/nairobi/the-grand-safari"
          render={(props)=> <GrandSafari {...props}/>}
        />
        <Route 
          path="/kenya/nairobi/kenyan-safari"
          render={(props)=> <KenyanSafariNbo {...props}/>}
        />
        <Route 
          path="/kenya/nairobi/mara-to-nakuru"
          render={(props)=> <MaraNakuruNbo {...props}/>}
        />
        <Route 
          path="/kenya/nairobi/red-mud-safari"
          render={(props)=> <RedMudNbo {...props}/>}
        />

        <Route 
          path="/cross-country/amboseli-to-ngorongoro"
          render={(props)=> <AmboseliNgorongoro {...props}/>}
        />
        <Route 
          path="/cross-country/secrets-of-africa1"
          render={(props)=> <SecretsOfEA {...props}/>}
        />
        <Route 
          path="/cross-country/secrets-of-africa2"
          render={(props)=> <SecretsofEALong {...props}/>}
        />

        
        <Route 
          path="/tanzania/manyara-serengeti-ngorongoro"
          render={(props)=> <ManyaraSerengetiNgoro {...props}/>}
        />
        <Route 
          path="/tanzania/ngorongoro-tour"
          render={(props)=> <Ngorongoro {...props}/>}
        />
        <Route 
          path="/tanzania/serengeti-fly-in"
          render={(props)=> <SerengetiFlyIn {...props}/>}
        />
        <Route 
          path="/tanzania/packaged-tour"
          render={(props)=> <TzPackaged {...props}/>}
        />
        <Redirect to="/index" />
        <Redirect from="/" to="/index" />
      </Switch>
    </Switch>
  </BrowserRouter>
);
