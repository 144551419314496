import DefaultFooter from 'components/Footers/DefaultFooter'
import LandingPageHeader from 'components/Headers/LandingPageHeader'
import ExamplesNavbar from 'components/Navbars/ExamplesNavbar'
import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import KTSFooter from 'components/Footers/KTSFooter'
import ATS1 from '../../../assets/img/KTSImages/Images/NrbTours/AuthenticKenyaSafari.JPG'
import ATS2 from '../../../assets/img/KTSImages/Images/NrbTours/AuthenticKenyaSafari(1).JPG'
import ATS3 from '../../../assets/img/KTSImages/Images/NrbTours/AuthenticSafariExperience.JPG'
import ATS4  from '../../../assets/img/KTSImages/Images/NrbTours/AuthenticSafariexperience(1).JPG'
import Conditions from '../../../assets/objects/TourConditions';


function AuthSafari() {
    React.useEffect(()=>{
        window.scrollTo(0,0);
    })
    return (
        <div>
            <ExamplesNavbar/>
            <LandingPageHeader/>
            <div style={{marginLeft:'10%', marginRight:'10%', color:'black'}}>
                <h2 className='title text-center mb-0' id='content'>Authentic Kenyan Safari</h2>
                <h3 className='text-center'>8 nights, 9 days</h3>
                <h4 className='text-center'>Available all year round</h4>
                <Row>
                    <h3 className='text-center'>An overview</h3>
                    <p className='text-justify'>
                        This package includes some of the most well-known parks in Kenya, from Masai Mara National
                        Reserve, Lake Nakuru National Park, Amboseli National Park and Tsavo East & West, beginning
                        from and ending in Nairobi. Drop us an email/message to find out more!
                        <br/>
                        <br/>
                        Email: <a href="mailto:ptskenya@gmail.com">ptskenya@gmail.com</a> / <a href="mailto:info@kenyatoursafari.com">info@kenyatoursafari.com</a>
                        Social media: Follow us on Instagram as @newkenyatravel & Facebook: New Kenya Travel &
                        Tour Safaris LTD
                        <br/>
                        <br/>
                    </p>
                </Row>
                <Row>
                    <Col>
                        <img src={ATS1} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <img src={ATS2} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <img src={ATS3} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                </Row>
                <Row>
                    <h3 className='title text-center'>Detailed View</h3>
                </Row>
                <Row>
                    <p classname=''><b>Day 1: Nairobi</b></p>
                    <p className='text-justify'>
                        Upon arrival into Nairobi, clients met and transferred on private basis to the hotel
                        <b> Overnight at Sarova Stanley or Similar on bed and breakfast basis</b>
                    </p>
                    <p classname='text-left'><b>Day 2: Nairobi - Masai Mara</b></p>
                    <p className='text-justify'>
                        Following an early morning breakfast, depart for Masai Mara National Reserve, one of East Africa’s
                        most famous reserve. The Mara consists of undulating hills and great rolling grasslands that sustain
                        some of the most varied wildlife in Africa. Have lunch at the lodge and some leisure time. Shortly
                        after, depart for an afternoon game drive, returning to the lodge late afternoon. Enjoy some
                        leisure facilities at the lodge/camp, followed by dinner and overnight.
                        <br/>
                        <b>Overnight in Masai Mara on full board basis</b>
                    </p>
                    <img src={ATS4} style={{marginBottom:'5%',width:'50%',marginLeft:'25%'}} className='img-raised'/>
                    <p classname='text-left'><b>Day 3: Full day Game-drive in Masai Mara</b></p>
                    <p className='text-justify'>
                        After breakfast, depart for full day game drive in Masai Mara game reserve with packed lunch
                        boxes. You will have the opportunity to see Mara river, a river in Mara Region in Tanzania and
                        Narok County in Kenya, and lies across the migration path of ungulates in the Serengeti/Masai
                        Mara game reserves. Hippopotami and Nile crocodiles are found in large groups in the Mara and
                        Talek rivers. Leopards, hyenas, cheetahs, jackals, and bat-eared foxes can also be found in the
                        reserve. The plains between the Mara River and the Esoit Siria Escarpment are probably the best
                        area for game viewing, in particular regarding lions and cheetahs.
                        <br/>
                        <br/>
                        Return to the lodge/camp late afternoon for dinner.
                        <b> Overnight in Masai Mara on full board basis</b>
                    </p>
                    <p classname='text-left'><b>Day 4: Masai Mara - Lake Nakuru</b></p>
                    <p className='text-justify'>
                        After breakfast, check out and depart for Nakuru, arriving in time for lunch. Following lunch,
                        depart for an afternoon game drive in Lake Nakuru National Park where you will have opportunity
                        to spot the endangered White Rhino and Black Rhino, Leopards, Giraffes, plenty of antelopes,
                        Flamingos and over 450 bird species!
                        <br/>
                        <b>Overnight in Lake Nakuru on full board basis</b>
                    </p>
                    <p classname='text-left'><b>Day 5: Lake Nakuru - Lake Naivasha</b></p>
                    <p className='text-justify'>
                        After breakfast, depart for Lake Naivasha, arriving in time for lunch. In the afternoon, depart for a
                        boat riding experience on Lake Naivasha. Spend the remainder of the evening at leisure enjoying
                        lodge facilities and be prepared for more thrilling wildlife adventures awaiting in Amboseli and
                        Taita Hills!
                        <br/>
                        <b>Overnight in Lake Naivasha on full board basis</b>
                    </p>
                    <p classname='text-left'><b>Day 6: Lake Naivasha - Amboseli National Park</b></p>
                    <p className='text-justify'>
                        Following breakfast at the lodge, depart for Amboseli National Park, which is famous known for the
                        spectacular views of Mount Kilimanjaro, the highest free-standing mountain in Africa. Following
                        lunch at the lodge and a siesta, depart for an afternoon game drive, returning to the lodge as the
                        sun sets for dinner and overnight.
                        <br/>
                        <b>Overnight in Amboseli on full board basis</b>
                    </p>
                    <p classname='text-left'><b>Day 7: Amboseli - Taita Hills</b></p>
                    <p className='text-justify'>
                        Following breakfast, depart for Taita Hills Sanctuary, arriving at Taita Hills Safari Lodge in time for
                        lunch. Following lunch, proceed for an afternoon game drive in the sanctuary whist hosts varied
                        species of birds and animals that include Lions, Elephants, Giraffe and Zebras, driving to Salt Lick
                        Safari Lodge as the sun sets. Dinner and overnight at Salt Lick Safari Lodge.
                        <br/>
                        <b>Overnight at Salt Lick Safari Lodge on full board basis</b>
                    </p>
                    <p classname='text-left'><b>Day 8: Taita Hills - Tsavo East</b></p>
                    <p className='text-justify'>
                        Following an early morning game drive and breakfast, depart for Tsavo National Park. The park
                        holds the country’s largest elephant population, popularly known as the Red Elephant. The game
                        drive meanders through the vast plains interspersed with woodlands that behold captivating views.
                        Exploring the hinterlands of the park is a rewarding experience with opportunities of seeing Lions,
                        Cheetahs, the great heards of Buffaloes stampeding across the plains. Clients will arrive in time for
                        lunch at the lodge. Following lunch, enjoy some time at leisure, followed by an afternoon game
                        drive in the park, returning to the hotel as the sun sets in time for dinner and overnight.
                        <br/>
                        <b>Overnight in Tsavo on full board basis</b>
                    </p>
                    <p classname='text-left'><b>Day 9: Tsavo East - Nairobi</b></p>
                    <p className='text-justify'>
                        After breakfast, depart for Nairobi with packed lunch boxes for the journey and a game drive en
                        route to exiting the park. Arrive Nairobi mid-afternoon and drop off to Nairobi hotel, with the
                        remainder of the evening at leisure.
                        <br/>
                        <b>Overnight in Tsavo on full board basis</b>
                    </p>
                </Row>
                <div>
                    <h3 className='title text-center'>End of Our Services</h3>
                    <h4>Our Cost Includes:</h4>
                    <ul>
                        {Conditions.inclusion.map((item,i)=>(
                            <li key={i}>{item.value}</li>
                        ))}
                    </ul>
            
                    <h4>Our Cost Excludes:</h4>
                    <ul>
                        {Conditions.exclusion.map((item,i)=>(
                            <li key={i}>{item.value}</li>
                        ))}
                    </ul>
                    
                    <h4>Additional Remarks:</h4>
                    <ul>
                        {Conditions.remarks.map((item,i)=>(
                            <li key={i}>{item.value}</li>
                        ))}
                    </ul>
                </div>
            </div>            
            <div className='send-button text-center'>
                <Button
                    color='success'
                    href="/enquiries"
                >
                    Booking Enquiry
                </Button>
                <br/>
                <br/>
            </div>
            <KTSFooter/>
            <DefaultFooter/>
        </div>
    )
}

export default AuthSafari;