import DefaultFooter from 'components/Footers/DefaultFooter'
import LandingPageHeader from 'components/Headers/LandingPageHeader'
import ExamplesNavbar from 'components/Navbars/ExamplesNavbar'
import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import KTSFooter from 'components/Footers/KTSFooter'
import RM1 from '../../../assets/img/KTSImages/Images/NrbTours/RedMudAmboseli.JPG'
import RM2 from '../../../assets/img/KTSImages/Images/NrbTours/RedMudAmboseli(1).JPG'
import RM3 from '../../../assets/img/KTSImages/Images/NrbTours/AuthenticSafariExperience.JPG'
import RM4  from '../../../assets/img/KTSImages/Images/NrbTours/MasaiMaraAndNakuruPackage(2).JPG'
import Conditions from '../../../assets/objects/TourConditions';


function RedMudNbo() {
    React.useEffect(()=>{
        window.scrollTo(0,0);
    })
    return (
        <div>
            <ExamplesNavbar/>
            <LandingPageHeader/>
            <div style={{marginLeft:'10%', marginRight:'10%'}}>
                <h2 className='title text-center mb-0' id='content'>Red Mud Safari</h2>
                <h3 className='title text-center'>2 nights, 3 days</h3>
                <h5 className='text-center'>Available all year round</h5>
                <Row>
                    <h3 className='title text-center'>An overview</h3>
                    <p className='text-justify'>
                        Want to experience the full, natural Kenyan Safari? Cannot make it to Maasai Mara?
                        <br/>
                        <br/>
                        From Nairobi, this is the perfect opportunity to experience the pleasure of The Big Five Safari! Drop us an
                        email/message to find out more!
                        <br/>
                        <br/>
                        Email: <a href="mailto:ptskenya@gmail.com">ptskenya@gmail.com</a> / <a href="mailto:info@kenyatoursafari.com">info@kenyatoursafari.com</a>
                        Social media: Follow us on Instagram as @newkenyatravel & Facebook: New Kenya Travel &
                        Tour Safaris LTD
                        <br/>
                        <br/>
                    </p>
                </Row>
                <Row>
                    <Col>
                        <img src={RM1} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <img src={RM2} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <img src={RM3} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                </Row>
                <Row>
                    <h3 className='title text-center'>Detailed View</h3>
                </Row>
                <Row>
                    <p classname=''><b>Day 1: Nairobi – Amboseli</b></p>
                    <p className='text-justify'>
                        Morning clients will be picked up from the hotel. Following a short briefing, depart for Amboseli National
                        Park, which is famous known for the spectacular views of Mount Kilimanjaro, the highest free-standing
                        mountain in Africa. Following lunch at the lodge and a siesta, depart for an afternoon game drive, returning
                        to the lodge as the sun sets for dinner and overnight.
                        <br/>
                        <b> Overnight at Amboseli Serena Safari Lodge or similar on full board basis</b>
                    </p>
                    <p classname='text-left'><b>Day 2: Amboseli National Park</b></p>
                    <p className='text-justify'>
                        Following breakfast, depart for a full day game drive in Amboseli National Park with picnic lunch boxes.
                        Return to the lodge late afternoon and enjoy the leisure facilities until dinner.
                        <b> Overnight at Amboseli Serena Safari Lodge or similar on full board basis</b>
                    </p>
                    <p classname='text-left'><b>Day 3: Amboseli – Nairobi</b></p>
                    <p className='text-justify'>
                        Depart for an early morning game drive and return to the lodge for breakfast. After breakfast, checkout and
                        drive back to Nairobi arriving afternoon. Clients will be dropped off to their Nairobi hotel for onward
                        arrangements (booked directly).
                    </p>
                </Row>
                    <h3 className='title text-center'>End of Our Services</h3>
                
                <div >
                    <h4>Our Cost Includes:</h4>
                    <ul>
                        {Conditions.inclusion.map((item,i)=>(
                            <li key={i}>{item.value}</li>
                        ))}
                    </ul>
            
                    <h4>Our Cost Excludes:</h4>
                    <ul>
                    {Conditions.exclusion.map((item,i)=>(
                        <li key={i}>{item.value}</li>
                    ))}
                    </ul>
                    
                    <h4>Additional Remarks:</h4>
                    <ul>
                    {Conditions.remarks.map((item,i)=>(
                        <li key={i}>{item.value}</li>
                    ))}
                    </ul>
                </div>
            </div>            
            <div className='send-button text-center'>
                <Button
                    color='success'
                    href="#pablo"
                >
                    Booking Enquiry
                </Button>
                <br/>
                <br/>
            </div>
            <KTSFooter/>
            <DefaultFooter/>
        </div>
    )
}

export default RedMudNbo;