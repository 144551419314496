import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

import ktsLogo1 from '../../assets/img/kts_logo_old.png';
import ktsLogo2 from '../../assets/img/KTS_logo_small.ico';
import DDMSubItem from "./SubNav";

function ExamplesNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("primary");
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  const [screenW, setScreenW] = useState();
  const [logo, setLogo] = useState();

  const [anchorTarget, setAnchorTarget] = useState();

  const kenHeaders = [
    {
      name: 'Tours from Mombasa',
      items: [
        { name: 'Beach and Wildlife', url: '/kenya/mombasa/beach-and-wildlife' },
        { name: 'Classic 5 Day Safari', url: '/kenya/mombasa/classic-tour' },
        { name: 'Classic 5 Day Reverse Safari', url: '/kenya/mombasa/classic-tour-reverse' },
        { name: 'Coast to City', url: '/kenya/mombasa/coast-to-city' },
        { name: 'Mombasa Getaway', url: '/kenya/mombasa/mombasa-getaway' },
        { name: 'Tsavo to Amboseli', url: '/kenya/mombasa/tsavo-to-amboseli' },
        { name: 'Tsavo to Taita', url: '/kenya/mombasa/tsavo-to-taita' }
      ]
    },
    {
      name: 'Tours from Nairobi',
      items: [
        { name: 'Authentic Kenyan Safari', url: '/kenya/nairobi/authentic-safari' },
        { name: 'Classic Safari Package', url: '/kenya/nairobi/classic-package' },
        { name: 'The Grand Safari', url: '/kenya/nairobi/the-grand-safari' },
        { name: 'Full Kenyan Safari', url: '/kenya/nairobi/kenyan-safari' },
        { name: 'Masaai Mara to Nakuru', url: '/kenya/nairobi/mara-to-nakuru' },
        { name: 'Red Mud Safari', url: '/kenya/nairobi/red-mud-safari' }
      ]
    },
    {
      name: 'Cross-Country Tours',
      items:[
        {name: 'Amboseli to Ngorongoro', url:'/cross-country/amboseli-to-ngorongoro'},
        {name: 'Secrets of East Africa (NBO - Arusha)', url:'/cross-country/secrets-of-africa1'},
        {name: 'Secrets of East Africa (Long)', url:'/cross-country/secrets-of-africa2'}
      ]
    }
  ];

  const tzHeaders = [
    {
      name: 'Tours from Tanzania',
      items: [
        { name: 'Manyara Serengeti and more', url: '/tanzania/manyara-serengeti-ngorongoro' },
        { name: 'Ngorongoro Crater', url: '/tanzania/ngorongoro-tour' },
        { name: 'Serengeti Fly-in', url: '/tanzania/serengeti-fly-in' },
        { name: 'Tanzania Packaged Tour', url: '/tanzania/packaged-tour' }
      ]
    },
    {
      name: 'Cross-Country Tours',
      items:[
        {name: 'Amboseli to Ngorongoro', url:'/cross-country/amboseli-to-ngorongoro'},
        {name: 'Secrets of East Africa (NBO - Arusha)', url:'/cross-country/secrets-of-africa1'},
        {name: 'Secrets of East Africa (Long)', url:'/cross-country/secrets-of-africa2'}
      ]
    },
  ];

  const getDimension = () => {
    setScreenW(window.innerWidth);
  }

  const [logoMargin] = useState(window.innerWidth/2)

  React.useEffect(()=>{
    setAnchorTarget(document.getElementById("contact-us"));
  })

  React.useEffect(() => {
    window.addEventListener('load', getDimension);
    window.addEventListener('resize', getDimension);
    if (screenW >= 500){
      setLogo(ktsLogo1)
    }
    else {
      setLogo(ktsLogo2)
    }

    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("primary");
        setCollapseOpen(false)
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("primary");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
      window.removeEventListener('load',getDimension);
      window.removeEventListener('resize',getDimension);
    };
  });

  React.useEffect(()=>{
    
  })

  const contactScroll = (e) => {
    e.preventDefault()
    anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' })
    setCollapseOpen(!collapseOpen)
  }

  return (
    <>  
      <Navbar className={"fixed-top position-relative" + navbarColor} color={navbarColor} expand="md" style={{backgroundImage:' linear-gradient(#00000085, #00000043', display:'flex'}}>
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              href="/index"
              id="navbar-brand"
            >
              <img src={logo} style={{maxHeight:'50px', minHeight:'30px'}}/>
            </NavbarBrand>
            <UncontrolledTooltip target="#navbar-brand">
              Designed by SySec
            </UncontrolledTooltip>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
                
              }}
              aria-expanded={collapseOpen}
              type="button"
              style={{marginLeft:logoMargin}}
            >
              {!collapseOpen && (
                <>
                  <span className="button-bar"/>
                  <span className="button-bar"/>
                  <span className="button-bar"/>
                </>
              )}
              {collapseOpen && (
                <i className="now-ui-icons ui-1_simple-remove" style={{color:'white'}}/>
              )}          
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar style={{}}>
              <div>
                <DDMSubItem headers={kenHeaders} buttonTitle="Kenya Tours"/>
              </div>
              <div>
                <DDMSubItem headers={tzHeaders} buttonTitle="Tanzania Tours"/>
              </div>
              <NavItem>
                <NavLink to="/enquiries" tag={Link}>
                  Booking Enquiries
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/about-us" tag={Link}>
                  About Us
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#contact-us" onClick={contactScroll}>
                  Contact Us
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://www.facebook.com/newkenyatour18/"
                  target="_blank"
                  id="facebook-tooltip"
                >
                  <i className="fab fa-facebook-square"></i>
                  <p className="d-lg-none d-xl-none">Facebook</p>
                </NavLink>
                <UncontrolledTooltip target="#facebook-tooltip">
                  Find us on Facebook
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://www.instagram.com/newkenyatravel/"
                  target="_blank"
                  id="instagram-tooltip"
                >
                  <i className="fab fa-instagram"></i>
                  <p className="d-lg-none d-xl-none">Instagram</p>
                </NavLink>
                <UncontrolledTooltip target="#instagram-tooltip">
                  Follow us on Instagram
                </UncontrolledTooltip>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ExamplesNavbar;
