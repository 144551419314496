import DefaultFooter from 'components/Footers/DefaultFooter'
import LandingPageHeader from 'components/Headers/LandingPageHeader'
import ExamplesNavbar from 'components/Navbars/ExamplesNavbar'
import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import KTSFooter from 'components/Footers/KTSFooter'
import ATS1 from '../../../assets/img/KTSImages/Images/NrbTours/AuthenticKenyaSafari.JPG'
import ATS2 from '../../../assets/img/KTSImages/Images/NrbTours/AuthenticKenyaSafari(1).JPG'
import ATS3 from '../../../assets/img/KTSImages/Images/NrbTours/AuthenticSafariExperience.JPG'
import ATS4  from '../../../assets/img/KTSImages/Images/NrbTours/AuthenticSafariexperience(1).JPG'
import TGS1 from '../../../assets/img/KTSImages/Images/NrbTours/TheGrandSafari.JPG'
import TGS2 from '../../../assets/img/KTSImages/Images/NrbTours/TheGrandSafari(1).JPG'
import TGS3 from '../../../assets/img/KTSImages/Images/NrbTours/TheGrandSafari(2).JPG'
import TGS4 from '../../../assets/img/KTSImages/Images/NrbTours/TheGrandSafari(3).JPG'
import Conditions from '../../../assets/objects/TourConditions';

function KenyanSafariNbo() {
    React.useEffect(()=>{
        window.scrollTo(0,0);
    })
    return (
        <div>
            <ExamplesNavbar/>
            <LandingPageHeader/>
            <div id='content'>
                <h2 className='title text-center'>The Full Kenyan Experience</h2>
                <h3 className="text-center">9 day safari from the City to the Coast - Available All year round</h3>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <Col sm='8' md='8' lg='8'>
                        <h3>An overview</h3>
                        <p>
                        Are you in for a lifetime of an adventure to Kenya, known for its well-known surprises in the
                        wilderness?
                        <br/>
                        <br/>
                        Why not let your heart lose to the thrilling encounter of where wilderness meets your dream
                        holiday?
                        <br/>
                        <br/>
                        Do you dream of experiencing a fresh and unique twist to an African safari with its culture, history
                        and wildlife? Then this is the perfect safari package for you!
                        <br/>
                        <br/>
                        This package includes some of the most well-known parks in Kenya, from Aberdare National Park,
                        Masai Mara National Reserve, to Amboseli National Park, beginning from Nairobi and ending in
                        Mombasa. Drop us an email/message to find out more!
                        <br/>
                        <br/>
                        Email: <a href="mailto:ptskenya@gmail.com">ptskenya@gmail.com</a> / <a href="mailto:info@kenyatoursafari.com">info@kenyatoursafari.com</a>
                        Social media: Follow us on Instagram as @newkenyatravel & Facebook: New Kenya Travel &
                        Tour Safaris LTD
                        </p>
                        <br/>
                    </Col>
                    <Col style={{alignItems:'center'}} md='4' sm='4' lg='4'>
                        <img src={ATS1} style={{marginBottom:'10%'}} className='img-raised'/>
                        <img src={ATS3} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                </Row>
                <h3 className='title text-center'>A detailed look</h3>
                <Row style={{marginLeft:'10%', marginRight:'10%', marginTop:'5%'}}>
                    <Col style={{alignItems:'center'}} md='4' sm='4' lg='4'>
                        <img src={ATS4} style={{marginBottom:'10%'}} className='img-raised'/>
                        <img src={ATS2} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        
                        <p classname='text-left'><b>Day 1: Nairobi – Aberdare National Park</b></p>
                        <p className='text-justify'>
                            0700HRS after breakfast in Nairobi and a short briefing, depart for Aberdare Country club.
                            Aberdare National Park provides a habitat for elephants, black rhinos, leopards, spotted hyenas,
                            olive baboons, black and white colobus monkeys, buffalos, warthogs and bushbucks among others.
                            Rare sightings include those of the Giant Forest hog, bongo, golden cat, serval cat, African wild cat,
                            African civet cat and the blue duiker. Visitors can indulge in picnics, trout fishing in the rivers and
                            camping in the moorlands. Bird viewing is rewarding, with over 250 species of birds in the park,
                            including the Jackson's Francolin, Sparrow hawks, goshawks, eagles, sunbirds and plovers. Arrive in
                            time for lunch at Aberdare Country Club or similar. Overnight at Aberdare Country Club or similar
                            on full board basis.
                        </p>
                        
                    </Col>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <p classname='text-left'><b>Day 2: Aberdare National Park – Lake Nakuru</b></p>
                    <p className='text-justify'>
                    0630HRS following breakfast, depart for Lake Nakuru National Park, famous for its millions of
                    seasonal pink flamingos and other aquatic birds. Check in at Lake Nakuru Sopa Lodge or similar and
                    enjoy your lunch. After lunch, depart for an afternoon game drive, returning to the lodge for
                    dinner and overnight.
                    <br/>
                    <b>Overnight at Lake Nakuru Sopa Lodge or similar on full board basis.</b>
                    </p>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%', marginTop:'5%'}}>
                    <Col style={{alignItems:'center'}} md='4' sm='4' lg='4'>
                        <img src={TGS1} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <p classname='text-left'><b>Day 3: Lake Nakuru – Masai Mara</b></p>
                        <p className='text-justify'>
                            Following an early morning breakfast, depart for Masai Mara National Reserve, one of East Africa’s
                            most famous reserve. The Mara consists of undulating hills and great rolling grasslands that sustain
                            some of the most varied wildlife in Africa. Have lunch at the lodge and some leisure time. Shortly
                            after, depart for an afternoon game drive, returning to the lodge late afternoon. Enjoy some
                            leisure facilities at the lodge/camp, followed by dinner and overnight.
                            <b>Overnight at a chosen accommodation in Masai Mara on full board basis</b>
                        </p>
                        <br/>
                    </Col>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <p classname='text-left'><b>Day 4: Full-day game drive in Masai Mara</b></p>
                    <p className='text-justify'>
                        After breakfast, depart for full day game drive in Masai Mara game reserve with packed lunch
                        boxes. You will have the opportunity to see Mara river, a river in Mara Region in Tanzania and
                        Narok County in Kenya, and lies across the migration path of ungulates in the Serengeti/Masai
                        Mara game reserves. Hippopotami and Nile crocodiles are found in large groups in the Mara and
                        Talek rivers. Leopards, hyenas, cheetahs, jackals, and bat-eared foxes can also be found in the
                        reserve. The plains between the Mara River and the Esoit Siria Escarpment are probably the best
                        area for game viewing, in particular regarding lion and cheetah.
                        <br/>
                        <br/>
                        Return to the lodge/camp late afternoon for dinner.    
                        <b> Overnight at a chosen accommodation in Masai Mara on full board basis</b>
                    </p>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <p classname='text-left'><b>Day 5: Masai Mara – Nairobi</b></p>
                    <p className='text-justify'>
                        Following breakfast this morning, drive towards Nairobi, arriving late afternoon. Check in at a
                        Nairobi hotel, with the remainder of the afternoon at leisure. Dinner and overnight at the hotel.
                        <b> Overnight at a chosen accommodation in Nairobi on full board basis</b>
                    </p>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <p classname='text-left'><b>Day 6: Nairobi - Amboseli</b></p>
                    <p className='text-justify'>
                        Following breakfast this morning, depart for Amboseli National park, arriving in time for lunch.
                        Amboseli National Park is famous known for the spectacular views of Mount Kilimanjaro, the
                        highest free-standing mountain in Africa. Following lunch at the lodge and a siesta, depart for an
                        afternoon game drive, returning to the lodge as the sun sets for dinner and overnight.
                        <b> Overnight at a chosen accommodation in Amboseli on full board basis</b>
                    </p>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <p classname='text-left'><b>Day 7: Amboseli National Park</b></p>
                    <p className='text-justify'>
                        Following breakfast, depart for a full day game drive in Amboseli National Park with picnic lunch
                        boxes. Return to the lodge late afternoon and enjoy the leisure facilities until dinner.
                        <b> Overnight at a chosen accommodation in Amboseli on full board basis</b>
                    </p>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%', marginTop:'5%'}}>
                    <Col style={{alignItems:'center'}} md='4' sm='4' lg='4'>
                        <img src={TGS2} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <p classname='text-left'><b>Day 8: Amboseli – Taita Hills</b></p>
                        <p className='text-justify'>
                            After breakfast, depart for Taita Hills Sanctuary, arriving at Taita Hills Safari Lodge in time for lunch.
                            Following lunch, proceed for an afternoon game drive in the sanctuary whist hosts varied species
                            of birds and animals that include Lions, Elephants, Giraffe and Zebras, driving to Salt Lick Safari
                            Lodge as the sun sets. Dinner and overnight at Salt Lick Safari Lodge.
                            <b> Overnight at Salt Lick Safari Lodge on full board basis</b>
                        </p>
                    </Col>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <p classname='text-left'><b>Day 9: Taita Hills – Mombasa/Watamu</b></p>
                    <p className='text-justify'>
                        Depart for an early morning game drive and return to the lodge for breakfast. After breakfast,
                        checkout and drive back to Mombasa arriving early afternoon. Clients will be dropped off to their
                        north coast / south coast hotel (on own arrangements).
                    </p>
                </Row>
                <h3 className='title text-center'>End of Our Services</h3>
                
                <div style={{marginLeft:'10%', marginRight:'10%'}}>
                    <h4>Our Cost Includes:</h4>
                    <ul>
                        {Conditions.inclusion.map((item,i)=>(
                            <li key={i}>{item.value}</li>
                        ))}
                    </ul>
                    <h4>Our Cost Excludes:</h4>
                    <ul>
                    {Conditions.exclusion.map((item,i)=>(
                        <li key={i}>{item.value}</li>
                    ))}
                    </ul>
                    
                    <h4>Additional Remarks:</h4>
                    <ul>
                    {Conditions.remarks.map((item,i)=>(
                        <li key={i}>{item.value}</li>
                    ))}
                    </ul>
                </div>
                
            </div>
            <div className='send-button text-center'>
                <Button
                    color='success'
                    href="/enquiries"
                >
                    Booking Enquiry
                </Button>
                <br/>
                <br/>

            </div>
            <KTSFooter/>
            <DefaultFooter/>
        </div>
    )
}

export default KenyanSafariNbo;