import DefaultFooter from 'components/Footers/DefaultFooter'
import LandingPageHeader from 'components/Headers/LandingPageHeader'
import ExamplesNavbar from 'components/Navbars/ExamplesNavbar'
import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import KTSFooter from 'components/Footers/KTSFooter'
import NGN1 from '../../assets/img/KTSImages/Images/Gallery/Gallery22.jpg';
import NGN3 from '../../assets/img/KTSImages/Images/Gallery/Gallery20.JPG';
import NGN4 from '../../assets/img/KTSImages/Images/Gallery/Gallery17.JPG';
import Conditions from '../../assets/objects/TourConditions';

function Ngorongoro() {
    React.useEffect(()=>{
        window.scrollTo(0,0);
    })
    return (
        <div>
            <ExamplesNavbar/>
            <LandingPageHeader/>
            <div id='content'>
                <h2 className='title text-center'>Ngorongoro</h2>
                <h3 className="title text-center">2 nights 3 days Safari - Available All Year round</h3>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <h3>An overview</h3>
                    <p>
                    This package includes a visit to the famous Ngorongoro Crater, beginning from and ending in
                    Arusha. Drop us an email/message to find out more!
                    <br/>
                    <br/>
                    Email: <a href="mailto:ptskenya@gmail.com">ptskenya@gmail.com</a> / <a href="mailto:info@kenyatoursafari.com">info@kenyatoursafari.com</a>
                    Social media: Follow us on Instagram as @newkenyatravel & Facebook: New Kenya Travel &
                    Tour Safaris LTD
                    </p>
                    <br/>
                </Row>
                
                <Row style={{marginLeft:'10%', marginRight:'10%', marginTop:'5%'}}>
                    <Col style={{alignItems:'center'}} md='4' sm='4' lg='4'>
                        <img src={NGN3} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <h3 className='text-center'>A detailed look</h3>
                        <p classname='text-left'><b>Day 1: Arusha - Ngorongoro Crater</b></p>
                        <p className='text-justify'>
                            Following breakfast, clients met at their Arusha Hotel / Airport and after a short briefing, proceed
                            to Ngorongoro arriving in time for lunch. (Please note clients arriving on a morning flight can also
                            depart for this tour on arrival. Breakfast will be included and then proceed for the safari. If clients
                            arrive in the afternoon, they will have to overnight in Arusha and depart for Ngorongoro the
                            following day).
                            <b> Overnight in Ngorongoro / Karatu on full board basis.</b>
                            <br/>
                            <b>Accommodation options: Ngorongoro Serena Lodge, Ngorongoro Sopa Lodge, Ngorongoro Farm
                            house, Ngorongoro Farmhouse Valley, Tloma Lodge, Ngorongoro Oldeani Mountain Lodge, Gibbs
                            Farm, Neptune Ngorongoro, Lemala Ngorongoro Lodge</b>
                        </p> 
                    </Col>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <p classname='text-left'><b>Day 2: Ngorongoro Crater</b></p>
                    <p className='text-justify'>
                        Depart on a full day of game viewing in the crater with picnic lunch boxes, returning to the lodge
                        late afternoon.
                        <b> Overnight at a chosen accommodation in Ngorongoro / Karatu.</b>
                    </p>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%', marginTop:'5%'}}>
                    <Col style={{alignItems:'center'}} md='4' sm='4' lg='4'>
                        <img src={NGN4} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <p classname='text-left'><b>Day 3: Ngorongoro - Arusha</b></p>
                        <p className='text-justify'>
                            Following breakfast, depart for Arusha, arriving in time for lunch at Arusha Coffee Lodge or similar.
                            After lunch, clients will be dropped off to Arusha hotel or Arusha Airport / Kilimanjaro
                            International Airport for onwards arrangements.
                        </p>
                    </Col>
                </Row>
                <h3 className='title text-center'>End of Our Services</h3>
                <div style={{marginLeft:'10%', marginRight:'10%'}}>
                    <h4>Our Cost Includes:</h4>
                    <ul>
                        {Conditions.inclusionTZ.map((item,i)=>(
                            <li key={i}>{item.value}</li>
                        ))}
                    </ul>
            
                    <h4>Our Cost Excludes:</h4>
                    <ul>
                    {Conditions.exclusion.map((item,i)=>(
                        <li key={i}>{item.value}</li>
                    ))}
                    </ul>
                    
                    <h4>Additional Remarks:</h4>
                    <ul>
                    {Conditions.remarks.map((item,i)=>(
                        <li key={i}>{item.value}</li>
                    ))}
                    </ul>
                </div>
            </div>
            <div className='send-button text-center'>
                <Button
                    color='success'
                    href="/enquiries"
                >
                    Booking Enquiry
                </Button>
            </div>
            <KTSFooter/>
            <DefaultFooter/>
        </div>
    )
}

export default Ngorongoro;