import DefaultFooter from 'components/Footers/DefaultFooter'
import LandingPageHeader from 'components/Headers/LandingPageHeader'
import ExamplesNavbar from 'components/Navbars/ExamplesNavbar'
import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import KTSFooter from 'components/Footers/KTSFooter'
import BW1 from '../../../assets/img/KTSImages/Images/MsaTours/BeachandWildlifeBreakItinerary.JPG'
import BW2 from '../../../assets/img/KTSImages/Images/MsaTours/3nights4daysTsavo.JPG'
import BW3 from '../../../assets/img/KTSImages/Images/MsaTours/MombasaGetaway.jpg'
import BW4 from '../../../assets/img/KTSImages/Images/MsaTours/MombasaGetaway(1).JPG'
import Conditions from '../../../assets/objects/TourConditions';

function BeachWildlife() {
    React.useEffect(()=>{
        window.scrollTo(0,0);
    })
    return (
        <div>
            <ExamplesNavbar/>
            <LandingPageHeader/>
            <div style={{marginLeft:'10%', marginRight:'10%', color:'black'}}>
                <h2 className='title text-center mb-0' id='content'>Beach Break and Wildlife Adventure</h2>
                <h3 className='title text-center'>8 nights, 9 days - Extra excursions Incl.</h3>
                <h4 className='text-center'>Begins in Mombasa, Kenya</h4>
                <h5 className='text-center'>Available all year round</h5>
                <Row>
                    <h3 className='text-center'>An overview</h3>
                    <p className='text-justify'>
                        Would you like to visit the best Mombasa highlights, as well as a range of safari parks and
                        experience a range of leisure accommodation and hospitality?
                        <br/>
                        <br/>
                        This package includes some of the most well-known parks in Kenya, from Tsavo East & West,
                        Amboseli National Park and Ngutuni Sanctuary, beginning from and ending in Mombasa. Drop us
                        an email/message to find out more!
                        <br/>
                        <br/>
                        Email: <a href="mailto:ptskenya@gmail.com">ptskenya@gmail.com</a> / <a href="mailto:info@kenyatoursafari.com">info@kenyatoursafari.com</a>
                        Social media: Follow us on Instagram as @newkenyatravel & Facebook: New Kenya Travel &
                        Tour Safaris LTD
                        <br/>
                        <br/>
                    </p>
                </Row>
                <Row>
                    <Col>
                        <img src={BW1} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <img src={BW2} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                    <Col>
                        <img src={BW3} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                </Row>
                <Row>
                    <h3 className='title text-center'>Detailed View</h3>
                </Row>
                <Row>
                    <p classname=''><b>Day 1: Mombasa - Tsavo East</b></p>
                    <p className='text-justify'>
                        Morning pick up from your hotel / residence and following a short briefing, depart for Tsavo
                        National Park, entering the park through Bachuma Gate. The park holds the country’s largest
                        elephant population, popularly known as the Red Elephant. The game drive meanders through the
                        vast plains interspersed with woodlands that behold captivating views. Exploring the hinterlands of
                        the park is a rewarding experience with opportunities of seeing Lions, Cheetahs, the great heards
                        of Buffaloes stampeding across the plains. Clients will arrive in time for lunch at the lodge.
                        Following lunch, enjoy some time at leisure, followed by an afternoon game drive in the park,
                        returning to the hotel as the sun sets in time for dinner and overnight.
                        <br/>
                        <b>Overnight in Tsavo East on full board basis</b>
                    </p>
                    <p classname='text-left'><b>Day 2: Tsavo - Amboseli</b></p>
                    <p className='text-justify'>
                        Following breakfast at the lodge, depart for Amboseli National Park Morning breakfast at the lodge.
                        Check out from the lodge and depart for Amboseli National Park, which is famous known for the
                        spectacular views of Mount Kilimanjaro, the highest free-standing mountain in Africa. Following
                        lunch at the lodge and a siesta, depart for an afternoon game drive, returning to the lodge as the
                        sun sets for dinner and overnight.
                        <br/>
                        <b>Overnight in Amboseli on full board basis</b>
                    </p>
                    <div>
                        <img src={BW4} style={{marginBottom:'5%',width:'50%',marginLeft:'25%'}} className='img-raised'/>
                    </div>
                    <p classname='text-left'><b>Day 3: Amboseli National Park</b></p>
                    <p className='text-justify'>
                        Following breakfast, depart for a full day game drive in Amboseli National Park with picnic lunch
                        boxes. Return to the lodge late afternoon and enjoy the leisure facilities until dinner.
                        <br/>
                        <b>Overnight in Amboseli on full board basis</b>
                    </p>
                    <p classname='text-left'><b>Day 4: Amboseli - Ngutuni Wildlife Conservacny</b></p>
                    <p className='text-justify'>
                        After breakfast, depart for Ngutuni Wildlife Conservancy, rrriving in time for lunch at the lodge.
                        Following lunch, depart for an afternoon game drive, returning to the lodge as the sun sets.
                        <br/>
                        <b>Overnight in Ngutuni Lodge on full board basis</b>
                    </p>
                    <p classname='text-left'><b>Day 5: Ngutuni - Diani</b></p>
                    <p className='text-justify'>
                        Following an early morning game drive and breakfast, depart for Mombasa, driving to South Coast.
                        Drop off to South Coast Beach Hotel in time for lunch, with the remainder of the afternoon at
                        leisure.
                        <br/>
                        <b>Overnight at hotel/resort in South Coast, Diani on all-inclusive basis</b>
                    </p>
                    <p classname='text-left'><b>Day 6: Shimba Hill ex. South Coast</b></p>
                    <p className='text-justify'>
                        After breakfast, depart for full day at Shimba Hills. Take a hike to the waterfalls in the morning.
                        Drive back to Shimba Lodge for lunch, one of the tree top hotels, where you have a chance to see
                        animals at the water hole. After lunch, head on for game drive, and then return back to hotel
                        around 1700hrs.
                        <br/>
                        <b>Tour Highlights:</b>{" "}Sheldricks Falls, Coastal rainforest, bird viewing, Various Viewpoints, lunch
                        included.
                        <br/>
                        <b>Overnight at hotel/resort in South Coast, Diani on all-inclusive basis</b>
                    </p>
                    <p classname='text-left'><b>Day 7: Full Day Wasini Island ex. South Coast</b></p>
                    <p className='text-justify'>
                        Following an early breakfast this morning, depart for full day Wasini Island Tour. This trip allows
                        you to sail on the boat, snorkel in clear waters where corals, fishes and sea plants are protected.
                        Dolphins can be seen close to the boat here, playfully jumping in front of the boat. Lunch will be
                        on a small tropical Wasini Island, where you can explore and experience the small local community.
                        Late afternoon, drop off to your South Coast hotel in time for dinner and overnight.
                        <br/>
                        <b>Overnight at hotel/resort in South Coast, Diani on all-inclusive basis</b>
                    </p>
                    <div>
                        <p classname='text-left'><b>Day 8: Relax at hotel</b></p>
                        <p className='text-justify'>
                            Today, enjoy a full day of relaxation and leisure at the hotel.
                            <br></br>
                            <b> Overnight at hotel/resort in South Coast, Diani on all-inclusive basis</b>
                        </p>
                    </div>
                    <div>
                        <p classname='text-left'><b>Day 9: Mombasa</b></p>
                        <p className='text-justify'>
                            After breakfast at the hotel, check out and drop off to Mombasa International Airport for your
                            onward arrangemenets. We hope to see you again soon!!
                        </p>
                    </div>
                </Row>
                <Row>
                    <Col lg='12' md='12' sm='12'><h3 className='title text-center'>End of Our Services</h3></Col>
                    
                </Row>
                <h4>Our Cost Includes:</h4>
                <ul>
                    {Conditions.inclusion.map((item,i)=>(
                        <li key={i}>{item.value}</li>
                    ))}
                </ul>
        
                <h4>Our Cost Excludes:</h4>
                <ul>
                {Conditions.exclusion.map((item,i)=>(
                    <li key={i}>{item.value}</li>
                ))}
                </ul>
                
                <h4>Additional Remarks:</h4>
                <ul>
                {Conditions.remarks.map((item,i)=>(
                    <li key={i}>{item.value}</li>
                ))}
                </ul>
            </div>            
            <div className='send-button text-center'>
                <Button
                    color='success'
                    href="/enquiries"
                >
                    Booking Enquiry
                </Button>
            </div>
            <KTSFooter/>
            <DefaultFooter/>
        </div>
    )
}

export default BeachWildlife;