import DefaultFooter from 'components/Footers/DefaultFooter'
import LandingPageHeader from 'components/Headers/LandingPageHeader'
import ExamplesNavbar from 'components/Navbars/ExamplesNavbar'
import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import KTSFooter from 'components/Footers/KTSFooter'
import AMN1 from '../../assets/img/KTSImages/Images/Gallery/Gallery17.JPG';
import AMN2 from '../../assets/img/KTSImages/Images/Gallery/Gallery19.JPG';
import AMN3 from '../../assets/img/KTSImages/Images/Gallery/Gallery20.JPG';
import Conditions from '../../assets/objects/TourConditions';

function AmboseliNgorongoro() {
    React.useEffect(()=>{
        window.scrollTo(0,0);
    })
    return (
        <div>
            <ExamplesNavbar/>
            <LandingPageHeader/>
            <div id='content'>
                <h2 className='title text-center'>Amboseli to Ngorongoro</h2>
                <h3 className="text-center">7 nights 8 day Safari - Available All Year round</h3>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <Col sm='8' md='8' lg='8'>
                        <h3>An overview</h3>
                        <p>
                        Do you dream of experiencing a fresh and unique twist to an African safari with its culture, history
                        and wildlife in two countries? Then this is the perfect safari package for you!
                        <br/>
                        <br/>
                        This package includes some of the most well-known parks in Kenya and Tanzania, beginning from
                        Nairobi, and ending in Arusha. Drop us an email/message to find out more!
                        <br/>
                        <br/>
                        Email: ptskenya@gmail.com / info@kenyatoursafari.com
                        Social media: Follow us on Instagram as @newkenyatravel & Facebook: New Kenya Travel &
                        Tour Safaris LTD
                        </p>
                        <br/>
                        <br/>
                    </Col>
                    <Col style={{alignItems:'center'}} md='4' sm='4' lg='4'>
                        <img src={AMN1} style={{marginBottom:'10%'}} className='img-raised'/>
                        <img src={AMN2} style={{marginBottom:'10%'}} className='img-raised'/>
                    </Col>
                </Row>
                <div style={{marginLeft:'10%', marginRight:'10%', marginTop:'5%'}}>
                    <h3 className='text-center'>A detailed look</h3>
                </div>
                <Row style={{marginLeft:'10%', marginRight:'10%', marginTop:'5%'}}>
                    <p classname='text-left'><b>Day 1: Nairobi</b></p>
                    <p className='text-justify'>
                        On arrival into Nairobi, clients transferred on private basis to the hotel.
                        <b> Overnight in Nairobi on full board basis.</b>
                        <b> Nairobi accommodation options: Eka Hotel, 2.5.4 Ole Sereni Hotel, Four Points Sheraton Airport
                        hotel, Sarova Stanley.</b>
                    </p> 
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <p classname='text-left'><b>Day 2: Nairobi - Amboseli National Park</b></p>
                    <p className='text-justify'>
                        Following an early breakfast, depart for Amboseli National Park, which is famously known for the
                        spectacular views of Mount Kilimanjaro, the highest free-standing mountain in Africa. Following
                        lunch at the lodge and a siesta, depart for an afternoon game drive, returning to the lodge as the
                        sun sets for dinner and overnight.
                        <b> Overnight in Amboseli on full board basis.</b>
                        <b> Accommodation options: Amboseli Serena Lodge, Ol Tukai Lodge, Amboseli Sentrim, Amboseli
                        Kibo Camp, Elewana Tortillis Camp or similar</b>
                    </p>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%', marginTop:'5%'}}>
                    <p classname='text-left'><b>Day 3: Amboseli National Park</b></p>
                    <p className='text-justify'>
                        Following breakfast, depart for a full day game drive in Amboseli National Park with picnic lunch
                        boxes. Return to the lodge late afternoon and enjoy the leisure facilities until dinner.
                        <b> Overnight at a chosen accommodation in Amboseli on full board basis</b>
                    </p>
                   
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <p classname='text-left'><b>Day 4: Amboseli – Arusha</b></p>
                    <p className='text-justify'>
                        After breakfast, depart for Arusha, arriving in time for lunch. Following lunch, clients will depart for
                        a Half-day Arusha City Tour, returning back to the hotel late afternoon. Dinner and overnight
                        included.    
                        <b> Overnight in Arusha on full board basis.</b>
                        <b> Accommodation options: Arusha Coffee Lodge, Arusha Serena Hotel, Four Points by Sheraton
                        Arusha, Gran Melia.</b>
                    </p>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <p classname='text-left'><b>Day 5: Arusha – Lake Manyara</b></p>
                    <p className='text-justify'>
                        After breakfast, depart for Lake Manyara arriving in time for lunch. Depart for your first afternoon
                        game drive in Tanzania’s Lake Manyara National Park. Dinner and overnight in Lake Manyara.
                        <b> Overnight in Lake Manyara on full board basis.</b>
                        <b> Accommodation options: Lake Manyara Serena lodge, Lake Manyara Wildlife Camp, Lake
                        Manyara Kilimamoja, Maramboi Tented Lodge, Burunge Tented Camp.</b>
                    </p>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%', marginTop:'5%'}}>
                    <p classname='text-left'><b>Day 6: Lake Manyara – Ngorongoro</b></p>
                    <p className='text-justify'>
                        Following a morning game drive and breakfast, depart for Ngorongoro, arriving in time for lunch.
                        After lunch, you have the remainder of the afternoon at leisure, followed by dinner and overnight
                        at camp / lodge.
                        <b> Overnight in Ngorongoro / Karatu on full board basis.</b>
                        <br/>
                        <b>Accommodation options: Ngorongoro Serena Lodge, Ngorongoro Sopa Lodge, Ngorongoro Farm
                        house, Ngorongoro Farmhouse Valley, Tloma Lodge, Ngorongoro Oldeani Mountain Lodge, Gibbs
                        Farm, Neptune Ngorongoro, Lemala Ngorongoro Lodge.</b>
                    </p>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <p classname='text-left'><b>Day 7: Ngorongoro Crater</b></p>
                    <p className='text-justify'>
                        Depart on a full day of game viewing in the crater with picnic lunch boxes, returning to the lodge
                        late afternoon.
                        <b> Overnight at a chosen accommodation in Ngorongoro / Karatu.</b>
                    </p>
                </Row>
                <Row style={{marginLeft:'10%', marginRight:'10%'}}>
                    <p classname='text-left'><b>Day 8: Ngorongoro – Arusha</b></p>
                    <p className='text-justify'>
                        Following breakfast, depart for Arusha, arriving in time for lunch at Arusha Coffee Lodge or similar.
                        After lunch, clients will be dropped off to Arusha hotel or Arusha Airport / Kilimanjaro
                        International Airport for onwards arrangements.
                    </p>
                </Row>
                <h3 className='title text-center'>End of Our Services</h3>
                <div style={{marginLeft:'10%', marginRight:'10%'}}>
                    <h4>Our Cost Includes:</h4>
                    <ul>
                        {Conditions.inclusion.map((item,i)=>(
                            <li key={i}>{item.value}</li>
                        ))}
                    </ul>
            
                    <h4>Our Cost Excludes:</h4>
                    <ul>
                    {Conditions.exclusion.map((item,i)=>(
                        <li key={i}>{item.value}</li>
                    ))}
                    </ul>
                    
                    <h4>Additional Remarks:</h4>
                    <ul>
                    {Conditions.remarks.map((item,i)=>(
                        <li key={i}>{item.value}</li>
                    ))}
                    </ul>
                </div>
            </div>
            <div className='send-button text-center'>
                <Button
                    color='success'
                    href="/enquiries"
                >
                    Booking Enquiry
                </Button>
            </div>
            <KTSFooter/>
            <DefaultFooter/>
        </div>
    )
}

export default AmboseliNgorongoro;