import LandingPageHeader from 'components/Headers/LandingPageHeader'
import ExamplesNavbar from 'components/Navbars/ExamplesNavbar'
import React, { useState } from 'react'
import { Button, Card, Col, FormGroup, Input, Label, Row, Modal, ModalBody } from 'reactstrap';
import Datetime from "react-datetime";
import KTSFooter from 'components/Footers/KTSFooter';
import DefaultFooter from 'components/Footers/DefaultFooter';
import emailjs from '@emailjs/browser'

function Enquiries() {
    const [enquiryData, setEnquiryData] = useState({
        fName:'',
        email:'',
        tel:'',
        message:'',
        countryDeptr:'',
        airport:'',
        fromDate: '',
        toDate: '',
        flexibility:'',
    })
    const [focus,setFocus] = useState(false)
    const [isValid,setIsValid] = useState(false);
    const [submitting, setSubmitting] = useState(false)
    const [resStatus, setResStatus] = useState()
    const [modal1, setModal1] = React.useState(false);
    const [modal2, setModal2] = React.useState(false);

    const handleChange = (e) => {
        if (e.target.name === 'fromDate'){
            const fDate = JSON.stringify(e.target.value._d)
            setEnquiryData({
                ...enquiryData,
                [e.target.name]:(fDate).split('T')[0].split('"')[1]
            })
        }
        else if(e.target.name === 'toDate'){
            const tDate = JSON.stringify(e.target.value._d)
            setEnquiryData({
                ...enquiryData,
                [e.target.name]:(tDate).split('T')[0].split('"')[1]
            })
        }
        else{
            setEnquiryData({
                ...enquiryData,
                [e.target.name]:e.target.value,
            })
            var regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (e.target.name === 'email' && e.target.value.length >= 0  && regEx.test(e.target.value)===false){
                setIsValid(false)
            }
            else{
                setIsValid(true)
                setEnquiryData({
                    ...enquiryData,
                    [e.target.name]:e.target.value,
                })
            }
        } 
    }

    React.useEffect(()=>{
        window.scrollTo(0,0);
    }, []);

    const submitForm = (event) => {
        event.preventDefault();
        if (isValid === true &&
            enquiryData.fName != '' &&
            enquiryData.airport != '' &&
            enquiryData.countryDeptr != '' &&
            enquiryData.tel != '' &&
            enquiryData.fromDate != '' &&
            enquiryData.toDate != '' &&
            enquiryData.flexibility != '' &&
            adultCount > 0
            ){
            setSubmitting(true)
            emailjs.send('service_ov6i92r','template_6753pa9',{...enquiryData,adultCount,childCount},'yZ-Yc766GScRDRzWw')
                .then((res)=>{
                    setModal2(true);
                    setResStatus(res.status);
                    setSubmitting(false);
                })
                .catch((err)=>{
                    setModal1(true);
                    setSubmitting(false);
                })
        }
        else{
            setModal1(true);
            setSubmitting(false);
        }
    }
    
    const [adultCount, setAdultCount] = useState(0)
    const addAdult = () => {
        setAdultCount(adultCount + 1);
    }

    const remAdult = () => {
        if(adultCount > 0){
            setAdultCount(adultCount - 1)
        }
        else{
            return;
        }
    }

    const [childCount, setChildCount] = useState(0);
    const addChild = () => {
        setChildCount(childCount + 1)
    }
    const remChild = () => {
        if(childCount > 0){
            setChildCount(childCount - 1)
        }
        else{
            return;
        }
    }

    return (
        <div>
            <ExamplesNavbar/>
            <LandingPageHeader/>
            <div style={{marginLeft:'5%', marginRight:'5%', marginTop:'10%'}} id='content'>
                <Card>
                <h2 className='title text-center'>Enquiry Form</h2>
                <div className='form-group' style={{marginLeft:'5%', marginRight:'5%'}}>
                    <h3 className='title'>Tell us About Yourself</h3>
                    <Row>
                        <Col sm='6' md='6' lg='6'>
                            <form>
                                <div>
                                    <label>Your Name</label>
                                    <br/>
                                    <input className='form-control' name="fName" value={enquiryData.fName} onChange={(e)=>handleChange(e)}/>
                                    {enquiryData.fName ==='' && (
                                        <div className='tex-center'>
                                            <p style={{color:'red', fontSize:'10px'}}>Name is required.</p>
                                        </div>
                                    )}
                                </div>
                                <br/>
                                <div>
                                    <label>Your Message...</label>
                                    <Input
                                        type="textarea"
                                        className='form-control'
                                        style={{width: '100%', resize:'none'}} 
                                        name='message' 
                                        value={enquiryData.message} 
                                        onChange={(e)=>handleChange(e)}
                                        placeholder='Any  extra information we may need...'
                                    />
                                </div>
                                
                                <br/>
                                <br/>
                                
                            </form>
                        </Col>
                        <Col sm='6' md='6' lg='6'>
                            <form>
                                <div>
                                    <label>Email address</label>
                                    <br/>
                                    <input 
                                        className='form-control' 
                                        type='email' 
                                        name='email' 
                                        value={enquiryData.email} 
                                        onChange={(e)=>handleChange(e)} 
                                        onFocus={()=>setFocus(true)} 
                                        onBlur={()=>setFocus(false)}
                                    />
                                    {isValid === false && focus===true && (
                                        <div className='tex-center'>
                                            <p style={{color:'red', fontSize:'10px'}}>Enter valid email.</p>
                                        </div>
                                    )}
                                </div>
                                <br/>
                                <div>
                                    <label>Telephone (With Country Code)</label>
                                    <input className='form-control' maxLength="15" name='tel' value={enquiryData.tel} onChange={(e)=>handleChange(e)}/>
                                    {enquiryData.tel === '' &&(
                                        <div className='tex-center'>
                                            <p style={{color:'red', fontSize:'10px'}}>Phone number is required.</p>
                                        </div>
                                    )}
                                </div>
                            </form>
                        </Col>
                    </Row>
                    <h3 className='title'>Your travel plans...</h3>
                    <Row>
                        <Col>
                            <label><b>Pick your travel dates</b></label>
                            <Row>
                                <Col sm='3' md='6' lg='6'>
                                    <label>From</label>
                                    <div className="datepicker-container">
                                        <FormGroup>
                                        <Datetime
                                            type='date'
                                            timeFormat={false}
                                            dateFormat='DD-MM-YYYY'
                                            utc={true}
                                            closeOnSelect={true}
                                            placeholder='Tour Departure'
                                            value={enquiryData.fromDate}
                                            onChange={(value)=>handleChange({target:{name:'fromDate',value}})}                                            
                                        />
                                        {enquiryData.fromDate === '' &&(
                                            <div className='tex-center'>
                                                <p style={{color:'red', fontSize:'10px'}}>Travel From date is required</p>
                                            </div>
                                        )}
                                        </FormGroup>
                                    </div>
                                </Col>
                                <Col sm='3' md='6' lg='6'>
                                    <label>To</label>
                                    <div className="datepicker-container">
                                        <FormGroup>
                                        <Datetime
                                            type='date'                        
                                            placeholder= "Tour Return"
                                            timeFormat={false}
                                            dateFormat='DD-MM-YYYY'
                                            utc={true}
                                            closeOnSelect={true}
                                            value={enquiryData.toDate}
                                            onChange={(value)=>handleChange({target:{name:'toDate',value}})}
                                        />
                                        {enquiryData.toDate === '' &&(
                                            <div className='tex-center'>
                                                <p style={{color:'red', fontSize:'10px'}}>Travel To date is required</p>
                                            </div>
                                        )}
                                        </FormGroup>
                                    </div>
                                </Col>
                            </Row>
                            <br/>
                            <br/>
                            <label>How flexible are your dates?</label>
                            <Row>
                                <FormGroup check className="form-check-radio" style={{marginLeft:'2%'}} name="flexibility" value={enquiryData.flexibility}>
                                    <Label check>
                                    <Input
                                        value="flexible"
                                        id="dateRadio1"
                                        name="flexibility"
                                        type="radio"
                                        onChange={(e)=>handleChange(e)}
                                    ></Input>
                                    <span className="form-check-sign"></span>
                                    Flexible
                                    </Label>
                                    {!enquiryData.flexibility &&(
                                        <div className='tex-center'>
                                            <p style={{color:'red', fontSize:'10px'}}>Your schedule flexibility is required</p>
                                        </div>
                                    )}
                                </FormGroup>
                                
                                <FormGroup check className="form-check-radio" style={{marginLeft:'2%'}}>
                                    <Label check>
                                    <Input
                                        value="not flexible"
                                        id="dateRadio2"
                                        name="flexibility"
                                        type="radio"
                                        onChange={(e)=>handleChange(e)}
                                    ></Input>
                                    <span className="form-check-sign"></span>
                                    Tight Schedule
                                    </Label>
                                </FormGroup>
                                
                                <div className="form-group mt-5">
                                    <label for="AirportArrivalSelect">Airport of Arrival</label> 
                                    <select class="form-control" id="AirportArrivalSelect" name='airport' value={enquiryData.airport} onChange={(e)=>handleChange(e)}>
                                        <option>Select...</option>
                                        <option>Jomo Kenyatta International Airport (NBO)</option>
                                        <option>Moi International Airport (MBA)</option>
                                        <option>Wilson Airport (WIL)</option>
                                        <option>Kisumu International Airport (KIS)</option>
                                        <option>Malindi International Airport (MYD)</option>
                                    </select>
                                    {enquiryData.airport === '' &&(
                                        <div className='tex-center'>
                                            <p style={{color:'red', fontSize:'10px'}}>Airport of arrival is required.</p>
                                        </div>
                                    )}
                                </div>
                            </Row>

                        </Col>
                        <Col>
                            <form>
                                <label>Country of Departure</label>
                                <br/>
                                <input className='form-control' name='countryDeptr' value={enquiryData.countryDeptr} onChange={(e)=>handleChange(e)}/>
                                {enquiryData.countryDeptr === '' &&(
                                    <div className='tex-center'>
                                        <p style={{color:'red', fontSize:'10px'}}>Country of departure is required.</p>
                                    </div>
                                )}
                                <br/>
                                <br/>
                                <label>Number of Adults</label>
                                <br/>
                                <div style={{display:'inline-flex', alignItems:'center'}}>
                                    <div>
                                        <a onClick={remAdult}>
                                            <i className='now-ui-icons ui-1_simple-delete'/>
                                        </a>    
                                    </div>
                                    <div>
                                        <input className='form-control' placeholder={adultCount} style={{width:'60px', textAlign:'center', marginLeft:'5px', marginRight:'5px'}}/>
                                    </div>
                                    <div>
                                        <a onClick={addAdult}>
                                            <i className='now-ui-icons ui-1_simple-add'/>
                                        </a>
                                    </div>
                                </div>
                                <br/>
                                {adultCount=== 0 &&(
                                    <div className='tex-center'>
                                        <p style={{color:'red', fontSize:'10px'}}>Number of people is required</p>
                                    </div>
                                )}
                                <br/>
                                <label>Number of Children</label>
                                
                                <br/>
                                <div style={{display:'inline-flex', alignItems:'center'}}>
                                    <div>
                                        <a onClick={remChild}>
                                            <i className='now-ui-icons ui-1_simple-delete'/>
                                        </a>    
                                    </div>
                                    <div>
                                        <input className='form-control' placeholder={childCount} style={{width:'60px', textAlign:'center', marginLeft:'5px', marginRight:'5px'}}/>
                                    </div>
                                    <div>
                                        <a onClick={addChild}>
                                            <i className='now-ui-icons ui-1_simple-add'/>
                                        </a>
                                    </div>
                                </div>
                                <br/>
                                
                                <br/>
                                                             
                            </form>
                        </Col>
                    </Row>
                </div>
                <div className='text-center'>
                    <Button
                        color='success'
                        href='#pablo'
                        style={{margin:"15px", height:'40px', width:'auto'}}
                        onClick={(e)=>submitForm(e)}
                    >
                        {submitting === true && (
                            <>
                            <div className='text-center' style={{display:'flex', color:'white'}}>
                                <p style={{color:'white', fontWeight:'bold'}}>Submitting</p>
                                <i className='now-ui-icons loader_refresh mt-1' style={{marginLeft:'10px'}}/>
                            </div>
                            </>
                        )
                        }
                        {submitting === false && (
                            <p style={{color:'white', fontWeight:'bold'}}>Submit</p>
                        )}
                    </Button>
                    {resStatus===200 && modal2 &&(
                        <Modal
                            modalClassName="modal-mini modal-success"
                            toggle={() => setModal2(false)}
                            isOpen={modal2}
                        >
                            <ModalBody>
                                <p>Enquiry Submitted Successfully!</p>
                                <small>We will get back to you shortly. Thank you.</small>
                            </ModalBody>
                            <div className="modal-footer">
                                <Button
                                    className="btn-neutral"
                                    color="neutral"
                                    type="button"
                                    onClick={() => setModal2(false)}
                                >
                                    Close
                                </Button>
                            </div>
                        </Modal>
                    )}
                    {resStatus!==200 && modal1 && (
                        <Modal
                        modalClassName="modal-mini modal-warning"
                        toggle={() => setModal1(false)}
                        isOpen={modal1}
                        >
                            <ModalBody>
                                <p>Enquiry Submission Failed!</p>
                                <small>Please enter all the required details. Thank you.</small>
                            </ModalBody>
                            <div className="modal-footer text-center">
                                <Button
                                    className="btn-neutral"
                                    color="neutral"
                                    type="button"
                                    onClick={() => setModal1(false)}
                                >
                                    Close
                                </Button>
                            </div>
                        </Modal>
                    )}
                    
                </div>
                </Card>
            </div>
            <KTSFooter/>
            <DefaultFooter/>
        </div>
    )
}

export default Enquiries